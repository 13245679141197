/* ------------------------------------------------------------ *\
    02. Fonts
\* ------------------------------------------------------------ */

@font-face {
 font-family: 'EFCircularLight';
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Light.eot');
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Light.eot?#iefix') format('embedded-opentype'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Light.woff') format('woff'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Light.woff2') format('woff2');
}

@font-face {
 font-family: 'EFCircularMedium';
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Medium.eot');
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Medium.eot?#iefix') format('embedded-opentype'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Medium.woff') format('woff'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Medium.woff2') format('woff2');
}

@font-face {
 font-family: 'EFCircularBold';
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Bold.eot');
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Bold.eot?#iefix') format('embedded-opentype'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Bold.woff') format('woff'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Bold.woff2') format('woff2');
}

@font-face {
 font-family: 'EFCircularBlack';
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Black.eot');
 src: url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Black.eot?#iefix') format('embedded-opentype'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Black.woff') format('woff'), url('https://efdigitalmarketing.github.io/templates/assets/fonts/EFcircular/EFCircular-Black.woff2') format('woff2');
}

/* ------------------------------------------------------------ *\
    03. Base Css
\* ------------------------------------------------------------ */

*, *::before, *::after {
 box-sizing: border-box;
 margin: 0;
 outline: 0 none;
 padding: 0;
}

*:focus {
 outline: 0;
}

a {
 text-decoration: none;
 outline: none;
}

img {
 display: inline-block;
 height: auto;
 vertical-align: middle;
}

img, iframe {
 border: 0 none;
}

img, iframe, video, audio, object {
 max-width: 100%;
}

ul {
 margin: 0;
 padding: 0;
 list-style-type: none;
}

body {
 margin: 0 !important;
 padding: 0 !important;
 background: #fff !important;
 letter-spacing: 0.25 !important;
 line-height: 1.6 !important;
 overflow-x: hidden!important;
 color: #000000;
}

body, p, div, span, button, i, label, a, input, select, textarea, option {
 font-family: 'EFCircularLight', Helvetica, Arial;
 font-size: 18px;
 letter-spacing: 0.38px;
 font-weight: 200;
}

#content {
 margin: 0 auto !important;
 width: 100% !important;
 float: left;
 padding: 0;
 background: #fff;
}

.col-full {
 width: 100%;
 float: left;
}

h1 {
 font-family: "EFCircularBold", Georgia, sans-serif;
 font-size: 36px;
 letter-spacing: 0.56px;
 font-weight: normal;
 line-height: 1.3;
}

h2 {
 font-family: "EFCircularBold", Georgia, sans-serif;
 font-size: 25px;
 font-weight: normal;
 line-height: 1.32;
 letter-spacing: 0.34px;
}

h3, h4 {
 font-family: "EFCircularBold", Georgia, sans-serif;
}

a, b, strong, button, .button {
 font-family: "EFCircularMedium", Georgia, sans-serif;
}

.sub-header {
 width: 100%;
 float: left;
 text-align: center;
 margin: 15px 0px;
}

.button {
 width: auto;
 text-align: center;
 padding: 10px 15px;
 border-radius: 100px;
 border: 1px solid transparent;
 display: inline-block;
 letter-spacing: 0.5px;
 font-size: 14px;
 -webkit-transition: all .3s ease-in-out;
 -moz-transition: all .3s ease-in-out;
 -o-transition: all .3s ease-in-out;
 -ms-transition: all .3s ease-in-out;
 transition: all .3s ease-in-out;
 cursor: pointer;
 
}

button {
 -webkit-transition: all .3s ease-in-out;
 -moz-transition: all .3s ease-in-out;
 -o-transition: all .3s ease-in-out;
 -ms-transition: all .3s ease-in-out;
 transition: all .3s ease-in-out;
}

.section-div {
 width: 100%;
 float: left;
 position: relative;
}

.center-container {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.desk-show {
 display: block;
}

.mob-show {
 display: none !important;
}

.desk-menu-show {
 display: block;
}

.mob-menu-show {
 display: none !important;
}

.none {
 display: none;
}

.hidden {
 display: none;
 pointer-events: none;
}

.opac {
 opacity: 0.2;
}

select::-ms-expand {
 display: none;
}

.mkt-sa{
    direction: rtl !important;
}

@media all and (max-width: 767px) {
 h1 {
  font-size: 28px;
 }
 h2 {
  font-size: 22px;
 }
 p {
  font-size: 15px;
  font-weight: 300;
 }
 .button {
  font-size: 12px;
 }
 .desk-show {
  display: none !important;
 }
 .mob-show {
  display: block !important;
 }
 .desk-menu-show {
  display: none !important;
 }
 .mob-menu-show {
  display: block !important;
 }
 .show-more {
  display: block;
  text-decoration: underline;
  font-size: 12px;
  color: #000;
  font-weight: 300;
 }
 .show-less {
  display: none;
  text-decoration: underline;
  font-size: 12px;
  color: #000;
  font-weight: 300;
 }
 .mob-show-toggle {
  display: none;
  height: auto;
  -webkit-transition: all 0s ease-in-out;
  -moz-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
 
}
}

@media all and (max-width: 1007px) {
 .desk-menu-show {
  display: none !important;
 }
 .mob-menu-show {
  display: block !important;
 }
}
/* ------------------------------------------------------------ *\
    Loading
\* ------------------------------------------------------------ */

#overlay {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 171, 235, 1);
    transition: 1s 0.4s;
  }
  
  .loaderCenter {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }
  
  .loaderCon {
    width: 100%;
    float: left;
    padding: 0 15px;
    position: relative;
    height: 100vh;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .loaderimag {
    width: 100%;
    float: left;
    padding-bottom: 0;
    text-align: center;
  }
  
  .loaderimag img {
    width: 50%;
  }
  
  #loadertxt {
    width: 100%;
    float: left;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    font-weight:bold;
    font-size: 13px;
  }
  
  #progress {
    height: 1px;
    background: #fff;
    position: relative;
    width: 0;
    float: left;
    transition: 1s;
  }
  
  #progstat {
    font-size: 0.7em;
    letter-spacing: 3px;
    position: relative;
    float: left;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #fff;
  }
  
/* ------------------------------------------------------------ *\
    Header
\* ------------------------------------------------------------ */

header {
 width: 100%;
 float: left;
 position: absolute;
 padding: 10px 30px;
 z-index:99;
}

.white{
    background: #fff;
    position: relative;
}

.transparent{
background: transparent;
}

.row-head {
 width: 100%;
 margin: 0 auto;
 max-width: 1200px;
}

.head-logo {
 width: 20%;
 float: left;
 padding: 0;
}

.head-logo img {
 max-width: 70%;
}

.head-link {
 width: 80%;
 float: left;
}

.head-link ul {
 margin: 0;
 padding: 0;
 list-style-type: none;
}

.head-link ul li {
 width: auto;
 float: right;
 padding: 6px 0 0 5px;
}

.head-link ul li a, .head-link ul li button {
 width: auto;
 float: left;
 font-size: 13px;
 text-decoration: none;
 text-align: center;
 color: #fff;
 background: transparent;
}
.white .head-link ul li a, .white .head-link ul li button {
    color: #333333;
   opacity: 0.5;
}


.head-link ul li a:hover, .head-link ul li button:hover {
 opacity: 1;
 text-decoration: none;
}

.head-link ul li a.active, .head-link ul li button.active {
 opacity: 1;
}

.ham-btn {
 padding: 11px 0 0;
 color: #000;
}

.contact-btn {
 color: #000;
}

.tel-btn {
 color: #000;
}

.tel-btn span {
 float: left;
 font-size: 11px;
}

.tel-btn .tel-ico {
 width: auto;
 margin-right: 5px;
}

.tel-ico img {
 width: 17px;
 height: 17px;
}

.head-btn {
 background: #000;
 color: #fff;
}

.contact-btn:hover {
 text-decoration: underline;
}

.btn-border{
    border:1px solid #fff;
    padding: 4px 24px;
    margin-top: 5px;
}

.head-link ul li.langNav .langDrop{
    background: none;
    border:none;
    color:#fff;
    font-family: "EFCircularMedium", Georgia, sans-serif;
    font-size: 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("images/arrow_down.png") no-repeat 97% 50% ;
    background-size: 11px 7px;
    width: 80px;
    opacity: 1;
    position: relative;
    z-index: 1;
}

.white .head-link ul li.langNav .langDrop{
    color:#333;
    background: url("images/arrow_black_down.png") no-repeat 97% 50% ;
    background-size: 11px 7px;
    width: 80px;
    opacity: 1;
    position: relative;
    z-index: 1;
}

.head-link ul li.langNav .langDrop option{
   background: rgba(0, 0, 0, 0.9);
}
.head-link ul li.langNav{
    border-bottom:1px solid #fff;
    padding: 10px 0 0;
    margin-left: 10px;
}
.head-link ul li.langNav button{
    display: none!important;
}

@media all and (max-width: 767px) {
 header {
  padding: 10px 24px;
 }
 .row-head {
  padding: 0;
 }
 .head-logo img {
  max-width: 70%;
  padding-top: 3px;
 }
 .head-link ul li {
  padding: 0 0 0 10px;
 }
 .head-link ul li a, .head-link ul li button {
  font-weight: 300;
  color: #191919;
 }
 .head-logo {
  position: relative;
  z-index: -1;
  padding: 0 15px 0 0;
  width: 50%;
 }
 .head-link {
  width: 50%;
 }
 .white{
     border-bottom:1px solid;
     border-color:rgba(0,0,0,0.1);
 }
 .head-link ul li.langNav{
   float: left;
   width: auto;
    padding: 0;
    margin-left: 0;
}

.langDrop{
  
    background: url("images/arrow_down.png") no-repeat 97% 65% ;
    background-size: 11px 7px;
   
}
.white .langDrop{
    color:#333;
    background: url("images/arrow_black_down.png") no-repeat 97% 65% ;
    background-size: 11px 7px;
}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
 .head-logo {
  width: 20%
 }
 .head-link {
  width: 80%;
  float: right;
 }
 .head-link ul li {
  padding: 6px 0 0;
 }
}

@keyframes App-logo-spin {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(360deg);
 }
}

/* ------------------------------------------------------------ *\
    07. Hero Section
\* ------------------------------------------------------------ */

.hero-section {
 width: 100vw;
 height: 750px;
 float: left;
 background: url("images/StageDesk.jpg");
 background-size: cover;
 background-position: top center;
 position: relative;
 background-repeat: no-repeat;
}

.hero-section::after {
background:rgba(0, 0, 0, 0.4);
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

/**** hero-section-inner-content ****/

.hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: center;
 top: 50%;
 -webkit-transform: translateY(-50%);
 transform: translateY(-50%);
 z-index: 20;
}

.hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.hero-content h1 {
 color: #fff;
 font-size: 48px;
 margin-bottom: 15px;
 text-align: left;
 max-width: 588px;
}

.hero-content p {
 color: #fff;
 font-size: 18px;
 width: 100%;
 float: left;
 text-align: left;
 max-width: 585px;
 font-family: "EFCircularMedium", Helvetica, Arial;
}

.hero-btn-area {
 width: 100%;
 float: left;
 text-align: left;
 padding: 30px 0px 0px;
}

.hero-btn {
 width: auto;
 color: #fff;
 float: left;
 box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
 background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
 padding: 10px 32px;
}

.hero-btn:hover {
    opacity: 0.8;
   }

.hero-regArea{
    width: 100%;
    float: left;
    padding-top: 32px;
}

.hero-regArea span{
    width: 100%;
    float: left;
    color: #fff;
    font-size: 12px;
    line-height: 1.33;
    padding-bottom: 4px;
    text-align: left
}
.regBtn {
    width: 100%;
    float: left;
    color: #fff;
    font-size: 12px;
    line-height: 1.33;
    text-decoration: underline;
    text-align: left;
    font-family:'EFCircularLight', Helvetica, Arial
   }



@media all and (max-width:767px) {
 .hero-section {
  height: 80vh;
  background: url("images/stageMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .hero-inner{
     top: 65%;
 }
 .hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .hero-content h1 {
  font-size: 24px;
  text-align: center;
 }
 .hero-content p {
  font-size: 16px;
  text-align: center;
  font-family: 'EFCircularLight', Helvetica, Arial;
 }
 .hero-btn-area {
  padding: 26px 0 0;
 }
 .hero-btn {
  width: 100%;
  padding:17px 0;
 }
 .hero-regArea span{
     text-align: center;
 }
 .regBtn{
     text-align: center;
 }
}

/* ------------------------------------------------------------ *\
    Detail Section
\* ------------------------------------------------------------ */

.detail-section {
    width: 100%;
    float: left;
    padding: 49px 0 300px;
    background: #00b9ff;
}

.detail-inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.detail-con {
    width: 100%;
    float: left;
}

.detail-con h1 {
    width: 100%;
    float: left;
    text-align: center;
    color: #fff;
    font-size: 48px;
}

.detail-con p {
    width: 100%;
    float: left;
    text-align: center;
    color: #fff;
    font-size: 24px;
    padding-top: 16px;
}

.btn-area {
    width: 100%;
    float: left;
    text-align: center;
    padding: 50px 0px 0px;
}

.detail-btn {
    width: 100%;
    max-width: 220px;
    font-weight: 300;
    margin-right: 20px;
    padding: 12px 15px;
    text-decoration: none;
}

.detloginBtn {
    background: #fff;
    color: #333;
}

.detregBtn {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}

@media all and (max-width:767px) {
    .detail-con h1 {
        font-size: 20px;
    }
    .detail-con p {
        font-size: 14px;
    }
    .detail-con {
        padding: 0 20px;
    }
    .detail-btn {
        max-width: 115px;
    }
}

/* ------------------------------------------------------------ *\
    Offer Section
\* ------------------------------------------------------------ */

.offer-section {
 width: 100%;
 float: left;
 position: relative;
padding:100px 0;
background: #f7f5f0;
}

.offer-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.offer-box {
 width: 100%;
 float: left;
 background: #fff;
 padding: 60px 102px;
 box-shadow: 0 2px 20px 0 rgba(21, 24, 36, 0.16);
}

.offer-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 40px;
 font-size: 32px;
}

.offer-list {
 width: 100%;
 float: left;
}

.offer-list ul {
 margin: 0;
 padding: 0;
 list-style-type: none;
}

.offer-list ul li {
 width: 33%;
 float: left;
 padding: 20px 0;
}

/* .offer-list ul li:nth-child(2), .offer-list ul li:nth-child(5) {
 border-left: 1px solid #dcdcdc;
 border-right: 1px solid #dcdcdc;
}

.border-bottom {
 border-bottom: 1px solid #dcdcdc;
} */

.offer-list ul li .offer-num {
 width: 100%;
 float: none;
 text-align: left;
 max-width: 215px;
 margin: 0 auto;
}

.offer-list ul li:nth-child(1) .offer-num, .offer-list ul li:nth-child(4) .offer-num {
 margin: 0;
}

.offer-list ul li .offer-text {
 width: 100%;
 float: left;
 text-align: center;
 padding-top: 20px;
}

.offer-list ul li:nth-child(1) .offer-text, .offer-list ul li:nth-child(4) .offer-text {
 width: 100%;
 float: left;
 text-align: left;
}

.offer-list ul li h1 {
 width: auto;
 padding-bottom: 5px;
 border-bottom: 2px solid #00b9ff;
 text-align: left;
 float: none;
 display: inline-block;
 font-size: 33px;
 opacity: 0.7;
}

.offer-list ul li h3 {
 width: auto;
 text-align: left;
 float: none;
 display: inline-block;
 font-size: 20px;
 max-width: 215px;
}

@media all and (max-width:767px) {
    .offer-section{
        padding: 48px 0;
    }
 .offer-area {
  max-width: 90%;
 }
 .offer-box {
  padding: 22px 24px;
 }
 .offer-box h1 {
  font-size: 20px;
 }
 .offer-list ul li {
  width: 50%;
  min-height: 120px;
 }
 .offer-list ul li:nth-child(2), .offer-list ul li:nth-child(5) {
  border: none;
 }
 .border-bottom {
  border-bottom: none;
 }
 .offer-list ul li h1 {
  font-size: 20px;
 }
 .offer-list ul li .offer-num {
  max-width: 115px;
  margin: 0;
 }
 .offer-list ul li h3 {
  font-size: 12px;
  max-width: 120px;
  font-family: 'EFCircularMedium', Helvetica, Arial;
 }
 .offer-list ul li:nth-child(1) .offer-num, .offer-list ul li:nth-child(3) .offer-num, .offer-list ul li:nth-child(5) .offer-num {
  margin: 0;
 }
 .offer-list ul li .offer-text {
     padding-top: 8px;
  text-align: left;
 }
 .offer-list ul li:nth-child(1), .offer-list ul li:nth-child(3), .offer-list ul li:nth-child(5) {
  border-right: 1px solid #dcdcdc;
 }
 .border-top {
  border-top: 1px solid #dcdcdc !important;
 }
 .offer-list ul li:nth-child(2), .offer-list ul li:nth-child(4), .offer-list ul li:nth-child(6) {
  padding-left: 20px;
 }

 
}

/* ------------------------------------------------------------ *\
    Program Section
\* ------------------------------------------------------------ */

.section-prog {
 width: 100%;
 float: left;
 background:#f7f5f0;
 padding:0 0 96px;
}

.prog-head{
    width: 100%;
    float: left;
    background: #fff;
    padding:73px 0 120px;
}

.prog-head h1 {
    width: 100%;
    float: left;
    text-align: left;
    padding-bottom: 50px;
    font-size: 32px;
    color: #191919;
   }

.prog-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.prog-box {
 width: 100%;
 float: left;
 margin-top: -140px;
}



.prog-row {
 width: 100%;
 float: left;
 padding-bottom: 4px;
}

.prog-half {
 width: 50%;
 float: left;
 height: 390px;
 overflow: hidden;
}

.lca {
 background: #f6c136;
}

.aya {
 background: #58723d;
}

.upa {
 background: #734084;
}

.jca {
 background: #f15e38;
}

.flex-center {
 width: 100%;
 float: left;
 display: -ms-flexbox;
 display: -webkit-box;
 display: flex;
 -ms-flex-align: center;
 -webkit-box-align: center;
 align-items: center;
 -webkit-box-pack: center;
 -ms-flex-pack: center;
 justify-content: center;
 -webkit-box-orient: vertical;
 -webkit-box-direction: normal;
 -ms-flex-direction: column;
 flex-direction: column;
 height: 390px;
 padding: 0 50px;
}

.flex-center h1 {
 width: 100%;
 float: left;
 text-align: left;
 color: #fff;
 font-size: 32px;
 padding-bottom: 0;
}

.flex-center h1 span {
 color: inherit;
 font-size: inherit;
 font-family: inherit;
 opacity: 0.7;
}

.flex-center h3 {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 24px;
 color: #fff;
 padding: 36px 0 33px;
}

.flex-center p {
 width: 100%;
 float: left;
 text-align: left;
 color: #fff;
 opacity: 0.8;
 font-size: 18px;
 padding-right: 50px;
}

@media all and (max-width:767px) {
    .section-prog{
        padding: 0 0 48px;
    }
.prog-head{
    padding:48px 20px 120px;
}
 .prog-box {
  padding: 0 20px;
 }
 .prog-head h1 {
  font-size: 20px;
 
 }
 .prog-row {
  margin-bottom: 30px;
 }
 .prog-half {
  width: 100%;
  height: 180px;
 }
 .mob-reverse {
  display: flex;
  flex-direction: column-reverse;
 }
 .flex-center {
  height: 203px;
  padding: 0 25px;
 }
 .flex-center h1{
     font-size: 16px;
     padding-bottom: 16px;
 }
 .flex-center h3, .flex-center p {
  font-size: 12px;
 }
 .flex-center h3 {
  padding: 15px 0;
 }
 .lca, .upa, .aya, .jca {
  width: 100%;
  float: right;
  margin-top: 0;
  z-index: 2;
  margin-left:0;
  height: 203px;
 }
}

/* ------------------------------------------------------------ *\
    Office Section
\* ------------------------------------------------------------ */

.section-office {
 width: 100%;
 float: left;
 background: #fff;
 min-height: 300px;
 padding-top: 60px;
 padding-bottom: 170px;
}

.office-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.office-box {
 width: 100%;
 float: left;
}

.office-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 50px;
 font-size: 32px;
 color: #191919;
}

.office-row {
 width: 100%;
 float: left;
}

.off60 {
 width: 60%;
 float: left;
 margin-bottom: 8px;
 min-height: 444px;
}

.off40 {
 width: 40%;
 float: left;
 margin-bottom: 8px;
 min-height: 444px;
}

.add1 {
 background: url("images/zurich.jpg");
 height: 444px;
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.add2 {
 background: url("images/boston.jpg");
 height: 444px;
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.add3 {
 background: url("images/london.jpg");
 height: 444px;
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.add4 {
 background: url("images/dubai.jpg");
 height: 444px;
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.pr8 {
 padding-right: 8px;
}

.offadd {
 width: 100%;
 float: left;
 position: relative;
}

.offadd::after {
 position: absolute;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 z-index: 0;
 content: " ";
 background: rgba(0, 0, 0, 0.3);
}

.offAddBlk {
 position: absolute;
 bottom: 40px;
 width: 100%;
 left: 0;
 right: 0;
 padding-left: 40px;
 z-index: 1;
}

.offAddBlk h1 {
 width: 100%;
 float: left;
 text-align: left;
 color: #fff;
 font-size: 30px;
 padding-bottom: 10px;
}

.offAddBlk p {
 width: 100%;
 float: left;
 text-align: left;
 color: #fff;
 font-size: 18px;
 padding-bottom: 5px;
}

.telLink {
 width: 100%;
 float: left;
 font-size: 18px;
 color: #fff;
 padding-bottom: 25px;
 font-family: 'EFCircularLight', Helvetica, Arial;
}
.ofc-btn-area{
    width: 100%;
    float: left;
    padding-top: 32px;
}

.offc-btn{
    width: auto;
    box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  background-color: #ffffff;
  padding: 13px 24px;
  color: #191919;
  float: right;
}

@media all and (max-width:767px) {
    .section-office{
        padding: 48px 0 ;
    }
 .office-box h1 {
  font-size: 20px;
  padding: 0 20px 20px;
 }
 
 .destination-parent .office-box h1{
        padding:0 0 20px;
 }

 .off60, .off40 {
  width: 100%;
 }
 .pr8 {
  padding-right: 0;
 }
 .offAddBlk {
  position: relative;
  bottom: 0;
  padding-left: 20px;
  width: 100%;
  float: left;
 }
 .desAcc .offAddBlk{
     padding-top: 70%;
 }
 .offAddBlk h1 {
  font-size: 20px;
 }
 .offAddBlk p, .telLink {
  font-size: 16px;
 }
 .ofc-btn-area{
     text-align: center;
 }
 .offc-btn{
     float: none;
 }
}

/* ------------------------------------------------------------ *\
    Accreditations Section
\* ------------------------------------------------------------ */

.section-accred {
 width: 100%;
 float: left;
 background: #f7f5f0;
 padding:98px 0 70px;
}

.accred-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.accred-box {
 width: 100%;
 float: left;
}

.accred-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 70px;
 font-size: 32px;
 color: #191919;
}

.accred-row {
 width: 100%;
 float: left;
}

.accred-row ul {
 margin: 0;
 padding: 0;
 list-style-type: none;
}

.slick-slider li {
 width: 25%;
 text-align: center;
}

.slick-slider li img {
 max-width: 50%;
 display: inline-block;
}

@media all and (max-width:767px) {
    .section-accred{
        padding:48px 0;
    }
 .accred-box h1 {
  font-size: 20px;
  text-align: left;
  padding: 0 20px 20px;
 }
 .slick-slider li {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
 }
 .slick-sliderli img {
  max-width: 150px;
 }
}

/* ------------------------------------------------------------ *\
    Button Section
\* ------------------------------------------------------------ */

.section-become-agent {
 width: 100%;
 float: left;
 background: #fff;
 text-align: center;
 padding: 98px 0;
}

.become-agent-con{
    width: 100%;
    max-width: 960px;
    margin:0 auto;
}

.become-agent-row{
    width: 100%;
    float: left;
}

.become-agent-row h1{
    width: 100%;
    float: left;
    text-align: center;
    color: #191919;
    font-size: 48px;
    line-height: 1.17;
    padding-bottom: 16px;
}

.become-agent-row p{
    width: 100%;
    float: left;
    text-align: center;
    font-size: 24px;
    color: #191919;
    padding-bottom: 32px;
}

.agentBtn {
 width: 100%;
 max-width: 260px;
 box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
 color: #fff;
 padding: 13px 32px;
}

.agentBtn:hover {
    box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
    background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
}



@media all and (max-width:767px) {
    .section-become-agent{
        padding: 48px 0;
    }
    .become-agent-row{
        padding: 0 20px;
    }
    .become-agent-row h1{
        font-size: 20px;
        padding-bottom: 8px;
    }
    .become-agent-row p{
        font-size: 14px;
    }
}

/* ------------------------------------------------------------ *\
    Agent Page Sections
\* ------------------------------------------------------------ */

.agent-hero {
 width: 100vw;
 height: auto;
 float: left;
 margin-bottom: 215px;
}



.agent-hero-inner {
 width: 100%;
 float: left;
 padding-top: 60px;
}

.agent-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.agent-hero-content h1 {
 font-size: 48px;
 margin-bottom: 10px;
 text-align: left;
}

.agent-hero-content p {
 font-size: 24px;
 width: 100%;
 float: left;
 text-align: left;
 
}

.section-material {
 width: 100%;
 float: left;
 background: #f7f5f0;
 min-height: 300px;
 padding-bottom: 100px;
}

.material-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.material-box {
 width: 100%;
 float: left;
 margin-top: -145px;
 min-height: 300px;
}

.material-box ul {
 margin: 0;
 padding: 0;
 list-style: none;
}

.material-box ul li {
 width: 33%;
 float: left;
 padding-right: 25px;
 padding-bottom: 20px;
}

.material-block {
 width: 100%;
 min-height: 485px;
 float: left;
 background: #fff;
 border-radius: 0;
 box-shadow: 0 2px 20px 0 rgba(21, 24, 36, 0.16);
 overflow: hidden;
 position: relative;
}

.matImg{
    width: 100%;
    float: left;
    height: 310px;
overflow: hidden;
}
.matDes {
 background: url("images/materialSchool.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 
}

.matCourse {
 background: url("images/materialProgram.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 
}

.matPQ {
 background: url("images/materialPQ.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 
}

.matM {
 background: url("images/materials.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 
}

.matPro {
 background: url("images/materialPromo.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 
}

.matOrder {
 background: url("images/materialOther.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 
}

.matAcc {
 background: url("images/materialAcc.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 
}

.materialCon {
width: 100%;
float: left;
background: #fff;
padding: 25px 29px;
}

.materialCon h1 {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 32px;
 padding-bottom: 5px;
 color: #000;
}

.materialCon p {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 16px;
 line-height: normal;
 color: #000;
}



@media all and (max-width:767px) {
    .agent-hero-inner{
     padding-top: 25px;   
    }
    .agent-hero{
        margin-bottom: 180px;
    }
 .agent-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .agent-hero-content h1 {
  font-size: 20px;
 }
 .agent-hero-content p {
  font-size: 12px;
 }
 .material-box {
  padding: 0 20px;
 }
 .material-box ul li {
  width: 100%;
  padding-right: 0;
 }
 .material-block {
  min-height: initial;
 }
 .matImg{
     height: 211px;
 }
 .materialCon {
  bottom: 0;
  padding: 32px 28px 50px;
  background: #fff;
 
 }
 .materialCon h1 {
  font-size: 26px;
  text-align: left;
  color: #000;
 }
 .materialCon p {
  font-size: 14px;
  font-weight: normal;
  display: block;
  text-align: left;
  color: #000;
 }
}

/* ------------------------------------------------------------ *\
    Schools hero Section
\* ------------------------------------------------------------ */

.school-hero {
 width: 100vw;
 height: 50vh;
 float: left;
 position: relative;
 margin-bottom: 70px;
}

.ny_hero {
 background: url("images/ny/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.mal_hero {
    background: url("images/malta/SchoolHeroDesk.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }
   .mala_hero {
    background: url("images/malaga/SchoolHeroDesk.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }
   .rica_hero {
    background: url("images/costa/SchoolHeroDesk.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }

.boston_hero {
 background: url("images/boston/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.washington_hero {
 background: url("images/washington/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.miami_hero {
 background: url("images/miami/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.losangeles_hero {
 background: url("images/losangeles/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.chicago_hero {
 background: url("images/chicago/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.sandiego_hero {
 background: url("images/sandiego/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.santabarbara_hero {
 background: url("images/santabarbara/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.sanfrancisco_hero {
 background: url("images/sanfrancisco/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.seattle_hero {
 background: url("images/seattle/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.honolulu_hero {
 background: url("images/honolulu/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.vancouver_hero {
 background: url("images/vancouver/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.vancouverisland_hero {
 background: url("images/vancouverisland/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.toronto_hero {
 background: url("images/toronto/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.london_hero {
 background: url("images/london/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.londonalgate_hero {
 background: url("images/londonalgate/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.bournemouth_hero {
 background: url("images/bournemouth/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.oxford_hero {
 background: url("images/oxford/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.cambridgeclare_hero {
 background: url("images/cambridgeclare/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.cambridge_hero {
 background: url("images/cambridge/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.brighton_hero {
 background: url("images/brighton/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.eastbourne_hero {
 background: url("images/eastbourne/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.bristol_hero {
 background: url("images/bristol/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.manchester_hero {
 background: url("images/manchester/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.dublin_hero {
 background: url("images/dublin/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.stjulians_hero {
 background: url("images/stjulians/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.capetown_hero {
 background: url("images/capetown/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.sydney_hero {
 background: url("images/sydney/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.perth_hero {
 background: url("images/perth/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.brisbane_hero {
 background: url("images/brisbane/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.auckland_hero {
 background: url("images/auckland/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.singapore_hero {
 background: url("images/singapore/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.paris_hero {
 background: url("images/paris/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.nice_hero {
 background: url("images/nice/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.straphael_hero {
 background: url("images/straphael/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.berlin_hero {
 background: url("images/berlin/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.munich_hero {
 background: url("images/munich/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.madrid_hero {
 background: url("images/madrid/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.barcelona_hero {
 background: url("images/barcelona/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.playa_hero {
 background: url("images/playa/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.lisbon_hero {
 background: url("images/lisbon/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.rome_hero {
 background: url("images/rome/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.dubai_hero {
 background: url("images/dubai/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.shanghai_hero {
 background: url("images/shanghai/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.seoul_hero {
 background: url("images/seoul/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.tokyo_hero {
 background: url("images/tokyo/SchoolHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.school-hero::after {
 background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.school-hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: left;
 bottom: 10%;
 z-index: 20;
}

.school-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.school-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: left;
}

.school-hero-content p {
 color: #fff;
 font-size: 18px;
 width: 100%;
 float: left;
 text-align: left;
 font-family: "EFCircularMedium", Helvetica, Arial;
}

@media all and (max-width:767px) {
 .school-hero {
  height: 40vh;
  margin-bottom: 50px;
 }
 .ny_hero {
  background: url("images/ny/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }

 .mal_hero {
    background: url("images/malta/SchoolHeroMob.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }
   .mala_hero {
    background: url("images/malaga/SchoolHeroMob.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }
   .rica_hero {
    background: url("images/costa/SchoolHeroMob.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }
 .boston_hero {
  background: url("images/boston/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .washington_hero {
  background: url("images/washington/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .miami_hero {
  background: url("images/miami/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .losangeles_hero {
  background: url("images/losangeles/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .chicago_hero {
  background: url("images/chicago/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .sandiego_hero {
  background: url("images/sandiego/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .santabarbara_hero {
  background: url("images/santabarbara/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .sanfrancisco_hero {
  background: url("images/sanfrancisco/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .seattle_hero {
  background: url("images/seattle/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .honolulu_hero {
  background: url("images/honolulu/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .vancouver_hero {
  background: url("images/vancouver/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .vancouverisland_hero {
  background: url("images/vancouverisland/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .toronto_hero {
  background: url("images/toronto/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .london_hero {
  background: url("images/london/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .londonalgate_hero {
  background: url("images/londonalgate/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .bournemouth_hero {
  background: url("images/bournemouth/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .oxford_hero {
  background: url("images/oxford/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .cambridgeclare_hero {
  background: url("images/cambridgeclare/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .cambridge_hero {
  background: url("images/cambridge/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .brighton_hero {
  background: url("images/brighton/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .eastbourne_hero {
  background: url("images/eastbourne/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .bristol_hero {
  background: url("images/bristol/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .manchester_hero {
  background: url("images/manchester/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .dublin_hero {
  background: url("images/dublin/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .stjulians_hero {
  background: url("images/stjulians/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .capetown_hero {
  background: url("images/capetown/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .sydney_hero {
  background: url("images/sydney/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .perth_hero {
  background: url("images/perth/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .brisbane_hero {
  background: url("images/brisbane/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .auckland_hero {
  background: url("images/auckland/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .singapore_hero {
  background: url("images/singapore/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .paris_hero {
  background: url("images/paris/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .nice_hero {
  background: url("images/nice/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .straphael_hero {
  background: url("images/straphael/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .berlin_hero {
  background: url("images/berlin/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .munich_hero {
  background: url("images/munich/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .madrid_hero {
  background: url("images/madrid/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .barcelona_hero {
  background: url("images/barcelona/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .playa_hero {
  background: url("images/playa/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .lisbon_hero {
  background: url("images/lisbon/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .rome_hero {
  background: url("images/rome/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .dubai_hero {
  background: url("images/dubai/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .shanghai_hero {
  background: url("images/shanghai/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .seoul_hero {
  background: url("images/seoul/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .tokyo_hero {
  background: url("images/tokyo/SchoolHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .school-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .school-hero-content h1 {
  font-size: 20px;
 }
 .school-hero-content p {
  font-size: 12px;
  max-width: 220px;
 }
}

/* ------------------------------------------------------------ *\
    Schools City Highlight Section
\* ------------------------------------------------------------ */

.section-scHighlight {
 width: 100%;
 float: left;
 margin-bottom: 70px;
}

.scArea {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.scCon {
 width: 100%;
 float: left;
 padding: 0;
}

.scCon h1 {
 width: 100%;
 float: left;
 font-size: 32px;
 color: #000;
 padding-bottom: 24px;
 font-family: "EFCircularMedium", Georgia, sans-serif;
 text-align: left;
}

.highlighList {
 width: 100%;
 float: left;
}

.highlighList ul {
 margin: 0;
 padding: 0;
}

.highlighList ul li {
 width: 100%;
 float: left;
 padding-bottom: 15px;
}

.highlighList ul li span.tickImg {
 width: 15px;
 float: left;
 padding: 0;
 margin-top: -1px;
}

.highlighList ul li span.tickImg img {
 max-width: 100%;
}

.highlighList ul li span.listCon {
 width: 90%;
 float: left;
 font-size: 18px;
 color: #000;
 opacity: 0.8;
 line-height: 1.72;
 padding: 0 0 0 10px;
}

.sc-btn-area {
 width: 100%;
 float: left;
 text-align: left;
 padding-top: 20px;
}

.sc-acc-btn {
 width: auto;
 float: left;
 padding: 10px 15px;
 color: #fff;
 box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
 background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
 border-radius: 100px;
}


@media all and (max-width:767px) {
 .section-scHighlight {
  margin-bottom: 50px;
 }
 .scCon {
  padding: 0 20px;
 }
 .scCon h1 {
  font-size: 16px;
 }
 .highlighList ul li span.listCon {
  font-size: 12px;
  font-family: EFCircularMedium, Georgia, sans-serif;
 }
 .highlighList ul li span.tickImg {
  margin-top: -5px;
 }
}

/* ------------------------------------------------------------ *\
    Schools Gallery Section
\* ------------------------------------------------------------ */

.section-scGallery {
 width: 100%;
 float: left;
 background: #fff;
 padding-top: 0;
 padding-bottom: 70px;
}

.scGallery-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.scGallery-box {
 width: 70%;
 float: left;
}

.scGallery-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 50px;
 font-size: 32px;
}

.scGallery-row {
 width: 100%;
 float: left;
}

@media all and (max-width:767px) {
 .section-scGallery {
  padding-bottom: 0;
 }
 .scGallery-box h1 {
  font-size: 16px;
  padding: 0 20px 20px;
 }
 .scGallery-box {
    width: 100%;
    float: left;
   }
}

/* ------------------------------------------------------------ *\
    19. Slick Slider
\* ------------------------------------------------------------ */

.slider {
 width: 100%;
 margin: 100px auto 0;
}

.slick-slide {
 margin: 0;
}

.slick-slide img {
 width: 100%;
}

.slick-prev::before, .slick-next::before {
 color: black;
}

.scGallery-row .slick-arrow {
 font-size: 0;
 text-indent: -4004px;
 width: 62px;
 height: 62px;
 border-radius: 100%;
 border: 1px solid #FFF;
 background: #fff;
 line-height: 60px;
 position: absolute;
 top: 50%;
 z-index: 9;
 cursor: pointer;
 -webkit-transform: translateY(-50%);
 transform: translateY(-50%);
 box-shadow: 0 2px 15px 0 rgba(25, 25, 25, 0.16);
}

.accred-row .slick-arrow {
 font-size: 0;
 text-indent: -4004px;
 width: 52px;
 height: 52px;
 border-radius: 100%;
 border: 1px solid #FFF;
 background: #fff;
 line-height: 60px;
 position: absolute;
 top: 50%;
 z-index: 9;
 cursor: pointer;
 -webkit-transform: translateY(-50%);
 transform: translateY(-50%);
 border: 1px solid #ccc;
}

.slick-arrow:before {
 display: inline-block;
 content: '';
 width: 10px;
 height: 10px;
 border-top: 1px solid #000;
 border-right: 1px solid #000;
 border-width: 1px;
 -webkit-transform: rotate(45deg);
 transform: rotate(45deg);
 position: absolute;
 top: 50%;
 left: 50%;
 margin-top: -4px;
 margin-left: -7px;
}

.slick-prev:before {
 -webkit-transform: rotate(225deg);
 transform: rotate(225deg);
 margin-left: -3px;
}

.slick-slider {
 position: relative;
 display: block;
 -webkit-box-sizing: border-box;
 box-sizing: border-box;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 -webkit-touch-callout: none;
 -khtml-user-select: none;
 -ms-touch-action: pan-y;
 touch-action: pan-y;
 -webkit-tap-highlight-color: transparent;
 width: 100%;
 float: left;
 overflow: hidden;
}

.slick-slider .slick-list, .slick-slider .slick-track {
 margin: 0 auto;
}

.slick-prev {
 left: 0%;
}

.slick-next {
 right: 0%;
}

.slick-disabled {
 opacity: 0;
 pointer-events: none;
}

.slick-list:focus {
 outline: none;
}

.slick-list.dragging {
 cursor: pointer;
 cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
 -webkit-transform: translate3d(0, 0, 0);
 transform: translate3d(0, 0, 0);
}

.slick-track {
 position: relative;
 top: 0;
 left: 0;
 display: block;
}

.slick-track:before, .slick-track:after {
 display: table;
 content: '';
}

.slick-track:after {
 clear: both;
}

.slick-loading .slick-track {
 visibility: hidden;
}

.slick-slide {
 display: none;
 float: left;
 height: 100%;
 min-height: 1px;
}

[dir='rtl'] .slick-slide {
 float: right;
}

.slick-slide img {
 display: block;
}

.slick-slide.slick-loading img {
 display: none;
}

.slick-slide.dragging img {
 pointer-events: none;
}

.slick-initialized .slick-slide {
 display: block;
}

.slick-loading .slick-slide {
 visibility: hidden;
}

.slick-vertical .slick-slide {
 display: block;
 height: auto;
 border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
 display: none;
}

/*** slick dots ***/

.slick-dots {
 position: absolute;
 bottom: 0;
 display: block;
 width: 100%;
 padding: 0;
 list-style: none;
 text-align: center;
}

.slick-dots li {
 position: relative;
 display: inline-block;
 width: 20px;
 height: 20px;
 margin: 0 5px;
 padding: 0;
 cursor: pointer;
}

.slick-dots li button {
 font-size: 0;
 line-height: 0;
 display: block;
 width: 10px;
 height: 10px;
 padding: 0;
 border-radius: 100%;
 cursor: pointer;
 position: relative;
 color: transparent;
 border: 0;
 outline: none;
 background: rgba(0, 0, 0, 0.2);
}

.slick-dots li button:hover, .slick-dots li button:focus {
 outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
 opacity: 1;
}

.slick-dots li button:before {
 font-family: 'slick';
 font-size: 6px;
 line-height: 20px;
 position: absolute;
 top: 0;
 left: 0;
 width: 10px;
 height: 10px;
 border-radius: 100%;
 content: '';
 text-align: center;
 opacity: .25;
 background: black;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
 opacity: 1;
 color: black;
}

@media all and (max-width:747px) {
 .slick-slide {
  margin: 0;
 }
 .scGallery-row .slick-slider {
  height: 200px;
 }
 .scGallery-row .slick-slide {
  margin: 0 10px 0 0;
 }
 .scGallery-row .slick-list {
  padding: 0 20px !important;
 }
 .scGallery-row .slick-arrow {
  display: none !important;
 }
 .accred-row .slick-arrow {
  box-shadow: 0 2px 10px 0 rgba(25, 25, 25, 0.16);
  width: 35px;
  height: 35px;
  top: 25%;
  -webkit-transform: translateY(-25%);
  transform: translateY(-25%);
 }
 .accred-row .slick-next {
  right: 2%;
 }
 .accred-row .slick-prev {
  left: 2%;
 }
}

/* ------------------------------------------------------------ *\
    Schools Video Section
\* ------------------------------------------------------------ */

.section-scVideo {
 width: 100%;
 float: left;
 background: #fff;
 margin-bottom: 70px;
}

.scVideo-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.scVideo-box {
 width: 50%;
 float: left;
}

.video-block {
 width: 100%;
 height: 464px;
 position: relative;
 float: left;
}

.react-player {
 position: absolute;
 top: 0;
 left: 0;
}

.react-player__shadow {
 background: initial !important;
 background-color: #fff !important;
 width: 80px !important;
 height: 80px !important;
}

.react-player__shadow .react-player__play-icon {
 border-color: transparent transparent transparent black !important;
 border-width: 8px 0px 8px 13px !important;
}

@media all and (max-width: 767px) {
 .section-scVideo {
  margin-bottom: 50px;
 }
 .videobg {
  background-size: cover;
 }
 .video-block {
  padding: 0 15px;
  height: 200px;
 }
 .react-player {
  position: relative;
  top: 0;
  left: 0;
 }
 .scVideo-box {
    width: 100%;
    float: left;
   }
}

/* ------------------------------------------------------------ *\
    Accomodation Section
\* ------------------------------------------------------------ */

.acc-hero {
 width: 100vw;
 height: 50vh;
 float: left;
 background: url("images/PQstageDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
 margin-bottom: 70px;
}

.acc-hero::after {
 background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.acc-hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: left;
 bottom: 10%;
 z-index: 1;
}

.acc-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.acc-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: left;
}

.acc-hero-content p {
 color: #fff;
 font-size: 18px;
 width: 100%;
 float: left;
 text-align: left;
 max-width: 860px;
 font-family: "EFCircularMedium", Helvetica, Arial;
}

.acc-parent {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.section-acc {
 width: 100%;
 float: left;
 min-height: 300px;
 margin-bottom: 70px;
}

.acc-box {
 width: 100%;
 float: left;
 min-height: 300px;
}

.acc-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 50px;
 font-size: 32px;
}

.acc-row {
 width: 100%;
 float: left;
}

.acc-half {
 width: 50%;
 float: left;
 height: 413px;
}

.homestay {
 background: #f6c136;
}

.resid {
 background: #58723d;
}

.campus {
 background: #734084;
}

.acc-flex-center {
 width: 100%;
 float: left;
 display: -ms-flexbox;
 display: -webkit-box;
 display: flex;
 -ms-flex-align: center;
 -webkit-box-align: center;
 align-items: center;
 -webkit-box-pack: center;
 -ms-flex-pack: center;
 justify-content: center;
 -webkit-box-orient: vertical;
 -webkit-box-direction: normal;
 -ms-flex-direction: column;
 flex-direction: column;
 height: 413px;
 padding: 0 50px;
}

.acc-flex-center h1 {
 width: 100%;
 float: left;
 text-align: left;
 color: #fff;
 font-size: 32px;
 padding-bottom: 30px;
}

.acc-flex-center h1 span {
 color: inherit;
 font-size: inherit;
 font-family: inherit;
 opacity: 0.7;
}

.acc-flex-center h3 {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 24px;
 color: #fff;
 padding: 36px 0 33px;
}

.acc-flex-center p {
 width: 100%;
 float: left;
 text-align: left;
 color: #fff;
 opacity: 0.8;
 font-size: 18px;
 padding-right: 50px;
}

@media all and (max-width:767px) {
 .acc-hero {
  height: 40vh;
  background: url("images/PQstageMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 50px;
 }
 .acc-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .acc-hero-content h1 {
  font-size: 20px;
 }
 .acc-hero-content p {
  font-size: 12px;
 }
 .acc-parent {
  padding: 0 20px;
 }
 .section-acc {
  min-height: 282px;
  margin-bottom: 50px;
 }
 .acc-box {
  padding: 0;
 }
 .acc-box h1 {
  font-size: 16px;
  padding-bottom: 20px;
 }
 .acc-row {
  margin-bottom: 30px;
 }
 .acc-half {
  width: 100%;
  height: auto;
 }
 .acc-mob-reverse {
  display: flex;
  flex-direction: column-reverse;
 }
 .acc-flex-center {
  height: 260px;
  padding: 0 25px;
 }
 .acc-flex-center h3, .acc-flex-center p {
  font-size: 12px;
 }
 .acc-flex-center h3 {
  padding: 15px 0;
 }
 
}

/* ------------------------------------------------------------ *\
    Materials Sections
\* ------------------------------------------------------------ */

.material-hero {
 width: 100vw;
 height: 630px;
 float: left;
 background: url("images/materialDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
 margin-bottom: 60px;
}

.material-hero::after {
 background:linear-gradient(to bottom, rgba(53, 66, 103, 0), #151824);;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.material-hero-inner {
    left: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.material-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.material-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: center;
}

.material-hero-content p {
    color: #fff;
    font-size: 24px;
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    text-align: center;
}

.materials-section {
 width: 100%;
 float: left;
 background: #fff;
 margin-bottom: 65px;
}

.materials-inner {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.materials-con {
 width: 100%;
 float: left;
}

.materials-con h1 {
 width: 100%;
 float: left;
 text-align: left;
 color: #000;
 font-size: 40px;
}

.materials-con p {
 width: 100%;
 float: left;
 text-align: left;
 color: #000;
 font-size: 16px;
 padding-top: 20px;
 max-width: 920px;
 font-family: "EFCircularMedium";
}

.material-videos {
 width: 100%;
 float: left;
 margin-top: 50px;
}

.material-videos-row {
 width: 100%;
 float: left;
}

.material-videos-row ul {
 margin: 0;
 padding: 0;
 list-style: none;
}

.material-videos-row ul li {
 width: 33%;
 float: left;
 padding-right: 25px;
 padding-bottom: 20px;
}

.material-videos-block {
 width: 100%;
 min-height: 384px;
 float: left;
 border-radius: 0;
 box-shadow: 0 2px 20px 0 rgba(21, 24, 36, 0.16);
 overflow: hidden;
 position: relative;
 cursor: pointer;
}

.materialVideo1 {
 background: url("images/prom1.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.materialVideo2 {
 background: url("images/prom2.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.materialVideo3 {
 background: url("images/prom3.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.materialVideo4 {
 background: url("images/prom4.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.materialVideo5 {
 background: url("images/prom5.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.materialVideo6 {
 background: url("images/prom6.jpg");
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}

.materialVideoCon {
 position: absolute;
 bottom: -100%;
 width: 100%;
 height: 100%;
 background: rgba(0, 0, 0, 0.6);
 padding-top: 45%;
 -webkit-transition: all 0.2s ease-out;
 -moz-transition: all 0.2s ease-out;
 -o-transition: all 0.2s ease-out;
 transition: all 0.2s ease-out;
}

.material-videos-block:hover .materialVideoCon {
 bottom: 0;
}

.materialVideoCon h1 {
 width: 100%;
 float: left;
 text-align: center;
 color: #fff;
 font-size: 32px;
 padding: 0 20px;
}

.materialsTop {
 border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

.section-materials {
 width: 100%;
 float: left;
 background: #f7f5f0;
 padding: 50px 0;
}

.materials-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.materials-box {
 width: 100%;
 float: left;
}

.materials-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 50px;
 font-size: 32px;
}

.dropArea, .btnArea {
 width: 28%;
 float: left;
 padding: 0;
}

.downBtn {
 width: 100%;
 max-width: 107px;
 float: left;
 padding: 10px 15px;
 color: #fff;
 box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
 background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
 border-radius: 100px;
}

.section-faq {
 width: 100%;
 float: left;
 padding: 65px 0 0;
}

.faq-pb {
 padding-bottom: 100px;
}

.faq-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.faq-box {
 width: 100%;
 float: left;
}

.faq-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 37px;
 font-size: 32px;
}

.tab-area {
 width: 100%;
 float: left;
}

.tab-area h1 {
 width: 100%;
 float: left;
 color: #24272c;
 font-size: 20px;
 font-family: "EFCircularMedium", Georgia, sans-serif;
 padding-bottom: 20px;
 line-height: 1.2;
}

.accPara {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 14px;
 color: #24272c;
 line-height: 1.22;
 padding-bottom: 20px;
}

.accPara a{
    color: inherit;
    text-decoration: underline;
    font-size: inherit;
    font-family: inherit;
}

.accPara li {
 width: 100%;
 float: left;
 font-size: inherit;
 font-family: inherit;
 color: inherit;
 line-height: inherit;
 padding-bottom: 10px;
}

@media all and (max-width:767px) {
 .material-hero {
  height: 70vh;
  background: url("images/materialMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 50px;
 }
 .material-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .material-hero-content h1 {
  font-size: 20px;
 }
 .material-hero-content p {
  font-size: 16px;
 }
 .materials-section {
  padding: 0 0 30px;
  margin-bottom: 0;
 }
 .materials-con {
  padding: 0 20px;
 }
 .materials-con h1 {
  font-size: 20px;
 }
 .materials-con p {
  font-size: 12px;
 }
 .materials-box {
  padding: 0 20px;
 }
 .materials-box h1 {
  font-size: 20px;
  padding-bottom: 25px;
 }
 .material-videos {
  margin-top: 30px;
 }
 .material-videos-row ul li {
  width: 100%;
  padding: 0 0 20px;
 }
 .material-videos-block {
  min-height: 220px;
 }
 .materialVideoCon {
  padding-top: 95px;
 }
 .materialVideoCon h1 {
  font-size: 20px;
  padding: 0;
 }
 .materialsTop {
  margin-top: 0;
 }
 .dropArea, .btnArea {
  padding: 0
 }
 .dropArea {
  width: 65%;
  padding-right: 10px;
 }
 .btnArea {
  width: 35%;
 }
 .downBtn {
  float: right;
 }
 .section-faq {
  padding: 50px 20px 0;
 }
 .faq-box h1 {
  font-size: 20px;
  padding-bottom: 15px;
 }
 .tab-area h1 {
  font-size: 14px;
  font-family: 'EFCircularRegular', Helvetica, Arial;
  padding-bottom: 20px;
 }
 .faq-pb {
  padding-bottom: 50px;
 }
 .tab-area .accordion .accordion-item .title::after {
  margin-right: -10px;
 }
 .accPara {
  font-family: 'EFCircularLight', Helvetica, Arial;
 }
}

/* ------------------------------------------------------------ *\
    Dropdown style
\* ------------------------------------------------------------ */

.Dropdown-root {
 position: relative;
}

.Dropdown-control {
 position: relative;
 overflow: hidden;
 background-color: white;
 box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
 border-radius: 4px;
 box-sizing: border-box;
 color: #333;
 cursor: default;
 outline: none;
 padding: 12px 52px 11px 10px;
 transition: all 200ms ease;
 float: left;
 width: 100%;
 max-width: 300px;
}

.Dropdown-arrow {
 border-color: #999 transparent transparent;
 border-style: solid;
 border-width: 5px 5px 0;
 content: ' ';
 display: block;
 height: 0;
 margin-top: -ceil(2.5);
 position: absolute;
 right: 10px;
 top: 20px;
 width: 0
}

.is-open .Dropdown-arrow {
 border-color: transparent transparent #999;
 border-width: 0 5px 5px;
}

.Dropdown-menu {
 background-color: white;
 border: 1px solid #ccc;
 box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
 box-sizing: border-box;
 margin-top: -1px;
 max-height: 200px;
 overflow-y: auto;
 position: absolute;
 top: 100%;
 width: 100%;
 max-width: 300px;
 z-index: 1000;
 -webkit-overflow-scrolling: touch;
 left: 0;
}

.Dropdown-menu .Dropdown-group>.Dropdown-title {
 padding: 8px 0;
 color: rgba(51, 51, 51, 1);
 font-family: "EFCircularBold", Georgia, sans-serif;
 border-bottom: 1px solid #000;
 margin: 0 15px 5px;
 font-size: 15px;
}

.Dropdown-placeholder {
 font-size: 14px;
 color: rgba(25, 25, 25, 0.5);
}

.Dropdown-option {
 box-sizing: border-box;
 color: rgba(25, 25, 25, 0.8);
 cursor: pointer;
 display: block;
 padding: 8px 15px;
 font-size: 14px;
}

.Dropdown-option:hover {
 background-color: #000;
 color: #fff;
}

/* .Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
} */

.Dropdown-noresults {
 box-sizing: border-box;
 color: #ccc;
 cursor: default;
 display: block;
 padding: 8px 10px;
}

@media all and (max-width:767px) {}

/* ------------------------------------------------------------ *\
    Tab style
\* ------------------------------------------------------------ */

.react-tabs {
 -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
 margin: 0 0 10px;
 padding: 0;
}

.react-tabs__tab {
 display: inline-block;
 border: 1px solid rgba(36, 39, 44, 0.15);
 bottom: -1px;
 position: relative;
 list-style: none;
 padding: 10px 12px;
 cursor: pointer;
 border-radius: 45px;
 background: #fff;
 color: #24272c;
 font-size: 10px;
 margin-right: 12px;
 width: 100px;
 text-align: center;
}

.react-tabs__tab--selected {
 background: #17354c;
 border-color: #17354c;
 color: #fff;
}

.react-tabs__tab--disabled {
 color: GrayText;
 cursor: default;
}

.react-tabs__tab:focus {
 box-shadow: 0 0 5px hsl(208, 99%, 50%);
 border-color: hsl(208, 99%, 50%);
 outline: none;
}

.react-tabs__tab:focus:after {
 content: "";
 position: absolute;
 height: 5px;
 left: -4px;
 right: -4px;
 bottom: -5px;
 background: #fff;
}

.react-tabs__tab-panel {
 display: none;
 padding-top: 10px;
}

.react-tabs__tab-panel--selected {
 display: block;
}

@media all and (max-width:767px) {
 .react-tabs__tab {
  width: auto;
  padding: 5px;
  margin-right: 5px;
 }
 .react-tabs__tab-panel {
  padding-top: 0;
 }
}

/* ------------------------------------------------------------ *\
    Courses Section
\* ------------------------------------------------------------ */

.courses-hero {
 width: 100vw;
 height: 630px;
 float: left;
 background: url("images/CoursesHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
 margin-bottom: 70px;
}

.courses-hero::after {
 background: linear-gradient(to bottom, rgba(53, 66, 103, 0), #151824);
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
 
}

.courses-hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: center;
 top: 50%;
 -webkit-transform: translateY(-50%);
 transform: translateY(-50%);
 z-index: 1;
}

.courses-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.courses-hero-content h1 {
 color: #fff;
 font-size: 48px;
 margin-bottom: 10px;
 text-align: center;
}

.courses-hero-content p {
 color: #fff;
 font-size: 24px;
 width: 100%;
 margin: 0 auto;
 text-align: center;
 max-width: 860px;
 
}

.borderCourse {
 border-bottom: 1px solid rgba(151, 151, 151, 0.5)
}

.section-courses {
 width: 100%;
 float: left;
 background: #f7f5f0;
 padding: 90px 0;
}

.coursemb100 {
 margin-bottom: 100px;
}

.courses-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.courses-box {
 width: 100%;
 float: left;
}

.courses-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 padding-bottom: 20px;
 font-size: 32px;
}

.courses-box p {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 16px;
 color: #000;
 padding-bottom: 30px;
}

.courses-box ul {
 margin: 0;
 padding: 0;
 float: left;
 padding-bottom: 30px;
 list-style: none;
}

.courses-box ul li {
 width: 100%;
 float: left;
 padding-bottom: 10px;
}

.courses-box ul li span.tickImg {
 width: 15px;
 float: left;
 padding: 0;
 margin-top: -1px;
}

.courses-box ul li span.tickImg img {
 max-width: 100%;
}

.courses-box ul li span.listCon {
 width: 90%;
 float: left;
 font-size: 16px;
 color: #000;
 opacity: 0.8;
 line-height: 1.72;
 padding: 0 0 0 10px;
}

.course-half {
 width: 50%;
 float: left;
}

.coursesdropArea, .coursesbtnArea {
 width: 28%;
 float: left;
 padding: 0;
}

.course-half .coursesdropArea {
 width: 55%;
 float: left;
 padding: 0;
}

.course-half .coursesbtnArea {
 width: 45%;
 float: left;
 padding: 0;
}

h1.coursesdropHead {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 18px;
 color: #000;
 padding-bottom: 23px;
 padding-left: 0;
}

.coursesdownBtn {
 width: 100%;
 max-width: 107px;
 float: left;
 padding: 10px 15px;
 color: #fff;
 box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
 background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
 border-radius: 100px;
}

@media all and (max-width:767px) {
 .courses-hero {
  height: 70vh;
  background: url("images/CoursesHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0;
 }
 .courses-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .courses-hero-content h1 {
  font-size: 24px;
 }
 .courses-hero-content p {
  font-size: 16px;
 }
 .courses-box {
  padding: 0 20px;
 }
 .courses-box h1 {
  font-size: 24px;
  padding-bottom: 9px;
 }
 .courses-box p{
     font-size: 12px;
 }
 .coursesdropArea, .coursesbtnArea {
  padding: 0
 }
 .coursesdropArea, .course-half .coursesdropArea {
  width: 65%;
  padding-right: 10px;
 }
 .coursesbtnArea, .course-half .coursesbtnArea {
  width: 35%;
 }
 .coursesdownBtn {
  float: right;
 }
 .courses-box ul li span.listCon {
  font-size: 12px;
 }
 h1.coursesdropHead {
  font-size: 16px;
  padding-left: 0;
  padding-bottom: 15px;
 }
 .coursemb100 {
  margin-bottom: 50px;
 }
 .course-half {
  width: 100%;
 }
 .course-half:nth-child(2n) {
  margin-top: 30px;
 }
 .section-courses{
     background: #fff;
     padding:20px 0 20px;
 }
 .borderCourse{
     border-bottom: none;
 }
}

/* ------------------------------------------------------------ *\
    Promos Page
\* ------------------------------------------------------------ */

.promos-hero {
 width: 100vw;
 height: 630px;
 float: left;
 background: url("images/PromoStageDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
 margin-bottom: 70px;
}

.promos-hero::after {
 background: linear-gradient(to bottom, rgba(53, 66, 103, 0), #151824);
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.promos-hero-inner {
    left: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.promos-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.promos-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: center;
}

.promos-hero-content p {
    color: #fff;
    font-size: 24px;
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    text-align: center;
}

.promosHead {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.promosHead h1 {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 32px;
 color: #000;
 padding-bottom: 50px;
}

.section-promos {
 width: 100%;
 float: left;
 background: #f7f5f0;
 padding: 62px 0 0;
}

.promos-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.promos-box {
 width: 100%;
 float: left;
 min-height: 300px;
}

.promos-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 color: #000;
 font-size: 32px;
 padding-bottom: 50px;
}

.promos-row {
 width: 100%;
 float: left;
 padding-bottom: 50px;
}

.promos-row h1 {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 20px;
 color: #000;
 padding-bottom: 25px;
}

.promos-row p {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 16px;
 color: #000;
}

.promos-row p span {
 font-size: inherit;
 font-family: inherit;
 color: inherit;
}

.promos-row p span.rMore {
 text-decoration: underline;
 padding-left: 3px;
}

.section-updates {
 width: 100%;
 float: left;
 padding: 62px 0 100px;
}

.update-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.update-box {
 width: 100%;
 float: left;
}

.update-box h1 {
 width: 100%;
 float: left;
 text-align: left;
 color: #000;
 font-size: 32px;
 padding-bottom: 50px;
}

.update-row {
 width: 100%;
 float: left;
 padding-bottom: 50px;
}

.update-row h1 {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 20px;
 color: #000;
 padding-bottom: 25px;
}

.update-row p {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 16px;
 color: #000;
}

.update-row ul {
 margin: 0;
 padding: 25px 0 0;
 list-style: none;
 float: left;
}

.update-row ul li {
 width: 100%;
 float: left;
 padding-bottom: 5px;
}

.update-row ul li span.tickImg {
 width: 15px;
 float: left;
 padding: 0;
 margin-top: -1px;
}

.update-row ul li span.listCon {
 width: 90%;
 float: left;
 font-size: 16px;
 color: #000;
 opacity: 0.8;
 line-height: 1.72;
 padding: 0 0 0 10px;
}

@media all and (max-width:767px) {
 .promos-hero {
  height: 70vh;
  background: url("images/PromoStageMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 50px;
 }
 .promos-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .promos-hero-content h1 {
  font-size: 20px;
 }
 .promos-hero-content p {
  font-size: 16px;
 }
 .section-promos {
  padding: 50px 20px;
 }
 .promos-row h1 {
  font-size: 14px;
 }
 .promos-row p {
  font-size: 12px;
  font-family: 'EFCircularLight', Helvetica, Arial;
 }
 .promos-box h1 {
  font-size: 20px;
  padding-bottom: 15px;
 }
 .section-updates {
  padding: 50px 20px;
 }
 .update-box h1 {
  font-size: 20px;
  padding-bottom: 15px;
 }
 .update-row, .promos-row {
  padding-bottom: 25px;
 }
 .update-row h1 {
  font-size: 14px;
 }
 .update-row p, .update-row ul li span.listCon {
  font-size: 12px;
  font-family: 'EFCircularLight', Helvetica, Arial;
 }
}

/* ------------------------------------------------------------ *\
    Destinations Section
\* ------------------------------------------------------------ */

.destination-hero {
 width: 100vw;
 height: 630px;
 float: left;
 background: url("images/DestStageDesk.jpg");
 background-size: cover;
 background-position: top center;
 position: relative;
 background-repeat: no-repeat;
 margin-bottom: 70px;
}

.destination-hero::after {
 background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.destination-hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: left;
 top: 50%;
 z-index: 1;
 transform: translateY(-50%);
}

.destination-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.destination-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: center;
}

.destination-hero-content p {
 color: #fff;
 font-size: 24px;
 width: 100%;
 max-width: 660px;
 margin:0 auto;
 text-align: center;
 
}

.destination-parent {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.section-destination {
 width: 100%;
 float: left;
 min-height: 300px;
 margin-bottom: 70px;
}


.des1 {
    background: url("images/desNY.jpg");
    height: 444px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }
   
   .des2 {
    background: url("images/desTOK.jpg");
    height: 444px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }
   
   .des3 {
    background: url("images/desLON.jpg");
    height: 444px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }
   
   .des4 {
    background: url("images/desDUB.jpg");
    height: 444px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }




.dest-box {
 width: 100%;
 float: left;
}

.destCol {
 width: 100%;
 float: left;
 min-height: 300px;
 padding: 150px;
 background: rgba(0, 0, 0, 0.5);
}

.destCol h1 {
 width: 100%;
 float: left;
 color: #fff;
 font-size: 32px;
 padding-bottom: 10px;
 border-bottom: 1px solid #fff;
 margin-bottom: 40px;
}

.destBlck{
    width: 100%;
    max-width:440px;
    margin:0 auto;
}

.destSelArea {
 width: 100%;
 float: left;
 padding-top: 20px;
}

.destDropArea  {
 width: 70%;
 float: left;
 padding-right: 10px;
}

 .destBtnArea {
    width: 25%;
    float: left;
    padding: 0;
   }

.destdownBtn {
 width: 100%;
 max-width: 107px;
 float: left;
 padding: 10px 15px;
 color: #fff;
 box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
 background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
 border-radius: 100px;
}

@media all and (max-width:767px) {
 .destination-hero {
  height: 70vh;
  background: url("images/DestStageMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 50px;
 }
 .destination-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .destination-hero-content h1 {
  font-size: 20px;
 }
 .destination-hero-content p {
  font-size: 16px;
 }
 .destination-parent {
  padding: 0 20px;
 }
 .section-destination {
  min-height: 282px;
  margin-bottom: 50px;
 }
 
 .destCol {
  padding: 50px;
  min-height: 282px;
 }
 .destCol h1 {
  font-size: 16px;
  padding-bottom: 23px;
 }
 .destDropArea, .destBtnArea {
  width: 100%;
  padding: 0;
 }
 .destDropArea {
  padding-bottom: 20px;
 }
 .destdownBtn {
  float: left;
  max-width: initial;
 }
 .destDropArea .Dropdown-control{
     max-width: initial;
 }
}

/* ------------------------------------------------------------ *\
    price quotations Section
\* ------------------------------------------------------------ */

.pq-hero {
 width: 100vw;
 height: 100vh;
 float: left;
 background: url("images/PQstageDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
}

.pq-hero::after {
 background:linear-gradient(to bottom, rgba(53, 66, 103, 0), #151824);
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.pq-hero-inner {
    left: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.pq-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.pq-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: center;
}

.pq-hero-content p {
 color: #fff;
    font-size: 24px;
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    text-align: center;
}

.pq-parent {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.section-pq {
 width: 100%;
 float: left;
 min-height: 300px;
 margin-bottom: 70px;
}

.pq_blk {
 background: url("images/PQblockDesk.jpg");
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
}

.pq-box {
 width: 100%;
 float: left;
}

.pqCol {
 width: 100%;
 float: left;
 min-height: 300px;
 padding: 150px;
 background: rgba(0, 0, 0, 0.5);
}

.pqCol h1 {
 width: 100%;
 float: left;
 color: #fff;
 font-size: 32px;
 padding-bottom: 10px;
 border-bottom: 1px solid #fff;
 margin-bottom: 40px;
}

.pqBlck {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
}

.pqSelArea {
 width: 100%;
 float: left;
 padding-top: 20px;
}

.pqDropArea {
 width: 80%;
 float: left;
 
}

.pqDropArea .Dropdown-root{
    width:45%;
    margin-right: 10px;
    float: left;
}
.pqDropArea .Dropdown-control{
    width:100%;
    
}


.pqBtnArea {
    width: 20%;
    float: left;
    padding: 0;
   }

.pqdownBtn {
 width: 100%;
 max-width: 107px;
 float: left;
 padding: 10px 15px;
 color: #fff;
 box-shadow: 0 2px 8px 0 rgba(71, 149, 214, 0.4);
 background-image: linear-gradient(to bottom, #06b4ea, #009ee8);
 border-radius: 100px;
}

@media all and (max-width:767px) {
 .pq-hero {
  height: 100vh;
  background: url("images/PQstageMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .pq-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .pq-hero-content h1 {
  font-size: 20px;
 }
 .pq-hero-content p {
  font-size: 16px;
 }
 .pq-parent {
  padding: 0 20px;
 }
 .section-pq {
  min-height: 282px;
  margin-bottom: 50px;
 }
 .pq_blk {
  background: url("images/PQblockMob.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
 }
 .pqCol {
  padding: 50px;
  min-height: 282px;
 }
 .pqCol h1 {
  font-size: 16px;
  padding-bottom: 23px;
 }
 .pqDropArea, .pqBtnArea {
  width: 100%;
  padding: 0;
 }
 .pqDropArea {
  padding-bottom: 20px;
 }
 .pqDropArea .Dropdown-root{
    width:100%;
    margin-bottom: 10px;
    float: left;
}

 .pqDropArea .Dropdown-control{
     max-width: initial;
 }
 .pqdownBtn {
  float: left;
  max-width: initial;
 }
}

/* ------------------------------------------------------------ *\
    Contact Us Section
\* ------------------------------------------------------------ */

.contactUs-section {
 width: 100vw;
 height: 630px;
 float: left;
 background: url("images/contactDesk.jpg");
 background-size: cover;
 background-position: top center;
 position: relative;
 background-repeat: no-repeat;
}

.contactUs-section::after {
 background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.contactUs-detail-section {
 width: 100%;
 float: left;
 padding: 100px 0;
 background: #00b9ff;
}

.contactUs-detail-inner {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.contactUs-detail-con {
 width: 100%;
 float: left;
}

.contactUs-detail-con h1 {
 width: 100%;
 float: left;
 text-align: center;
 color: #fff;
 font-size: 48px;
}

.contactUs-detail-con p {
 width: 100%;
 float: left;
 text-align: center;
 color: #fff;
 font-size: 24px;
 padding-top: 16px;
}

.contactUs-btn-area {
 width: 100%;
 float: left;
 text-align: center;
 padding: 50px 0px 0px;
}

.contactUs-detail-btn {
 width: 100%;
 max-width: 220px;
 font-weight: 300;
 margin-right: 20px;
 padding: 12px 15px;
 text-decoration: none;
}





@media all and (max-width:767px) {
 .contactUs-section {
  height: 40vh;
  background: url("images/contactMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .contactUs-detail-con h1 {
  font-size: 20px;
 }
 .contactUs-detail-con p {
  font-size: 14px;
 }
 .contactUs-detail-con {
  padding: 0 20px;
 }
 .contactUs-detail-btn {
  max-width: 115px;
 }
}

/* ------------------------------------------------------------ *\
    Order Material Sections
\* ------------------------------------------------------------ */

.omaterial-hero {
 width: 100vw;
 height: 50vh;
 float: left;
 background: url("images/CoursesHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
}

.omaterial-hero::after {
 background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.omaterial-hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: left;
 bottom: 10%;
 z-index: 1;
}

.omaterial-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.omaterial-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: left;
}

.omaterial-hero-content p {
 color: #fff;
 font-size: 18px;
 width: 100%;
 float: left;
 text-align: left;
 max-width: 860px;
 font-family: "EFCircularMedium", Helvetica, Arial;
}

.section-omaterial {
 width: 100%;
 float: left;
 min-height: 300px;
 padding: 50px 0 0;
}

.omaterial-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.omaterial-box {
 width: 100%;
 float: left;
}

.omaterial-box iframe {
 width: 100%;
 float: left;
 height: 610px;
}

@media all and (max-width:767px) {
 .omaterial-hero {
  height: 40vh;
  background: url("images/CoursesHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .omaterial-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .omaterial-hero-content h1 {
  font-size: 20px;
 }
 .omaterial-hero-content p {
  font-size: 12px;
 }
 .omaterial-box {
  padding: 0 20px;
 }
}

/* ------------------------------------------------------------ *\
    Sign In Sections
\* ------------------------------------------------------------ */

.signin-hero {
 width: 100vw;
 height: 30vh;
 float: left;
 background: url("images/CoursesHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
 margin-bottom: 0;
}

.signin-hero::after {
 background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.signin-hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: left;
 bottom: 10%;
 z-index: 1;
}

.signin-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.signin-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: left;
}

.signin-hero-content p {
 color: #fff;
 font-size: 18px;
 width: 100%;
 float: left;
 text-align: left;
 max-width: 860px;
 font-family: "EFCircularMedium", Helvetica, Arial;
}

.section-signin {
 width: 100%;
 float: left;
 min-height: 300px;
 padding: 50px 0;
}

.signin-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.signin-box {
 width: 100%;
 float: left;
}

.signin-box form {
 width: 100%;
 max-width: 320px;
 margin: 0 auto;
}

.signin-box .form-group {
 width: 100%;
 float: left;
 padding-bottom: 15px;
}

.signin-box .form-group label {
 width: 100%;
 float: left;
 padding-bottom: 5px;
 font-size: 13px;
 font-family: "EFCircularBold", Georgia, sans-serif;
 color: #000;
}

.signin-box .form-group input {
 width: 100%;
 float: left;
 border: 1px solid #ddd;
 background: #fff;
 border-radius: 5px;
 padding: 8px 10px;
 font-size: 13px;
}

.logBtn {
 min-width: 150px;
 background: #00b9ff;
 color: #fff;
 margin-right: 10px;
}

.logBtn:hover {
 background: #000;
 color: #fff;
}

.formError {
 width: 100%;
 float: left;
 text-align: left;
 font-size: 12px;
 padding-bottom: 10px;
 color: #b90000;
}

@media all and (max-width:767px) {
 .signin-hero {
  height: 30vh;
  background: url("images/CoursesHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .signin-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .signin-hero-content h1 {
  font-size: 20px;
 }
 .signin-hero-content p {
  font-size: 12px;
 }
 .signin-box {
  padding: 0 20px;
 }
 .logBtn {
  min-width: 120px;
 }
}

/* ------------------------------------------------------------ *\
    Register Sections
\* ------------------------------------------------------------ */

.register-hero {
 width: 100vw;
 height: 50vh;
 float: left;
 background: url("images/CoursesHeroDesk.jpg");
 background-size: cover;
 background-position: center;
 position: relative;
 background-repeat: no-repeat;
 margin-bottom: 0;
}

.register-hero::after {
 background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
 bottom: 0;
 content: "";
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 z-index: 0;
}

.register-hero-inner {
 left: 0;
 position: absolute;
 right: 0;
 text-align: left;
 bottom: 10%;
 z-index: 1;
}

.register-hero-content {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
 line-height: 30px;
}

.register-hero-content h1 {
 color: #fff;
 font-size: 50px;
 margin-bottom: 10px;
 text-align: left;
}

.register-hero-content p {
 color: #fff;
 font-size: 18px;
 width: 100%;
 float: left;
 text-align: left;
 max-width: 860px;
 font-family: "EFCircularMedium", Helvetica, Arial;
}

.section-register {
 width: 100%;
 float: left;
 min-height: 300px;
 padding: 50px 0;
}

.register-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.register-box {
 width: 100%;
 float: left;
}

.register-box iframe {
 width: 100%;
 float: left;
 height: 610px;
}

@media all and (max-width:767px) {
 .register-hero {
  height: 40vh;
  background: url("images/CoursesHeroMob.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .register-hero-content {
  padding: 0 20px;
  line-height: normal;
 }
 .register-hero-content h1 {
  font-size: 20px;
 }
 .register-hero-content p {
  font-size: 12px;
 }
 .register-box {
  padding: 0 20px;
 }
}

/* ------------------------------------------------------------ *\
    Footer Section
\* ------------------------------------------------------------ */

footer {
 width: 100%;
 float: left;
 background: #e6ecf0;
}

.foot-logo {
    width: 100%;
    float: left;
    padding: 60px 0;
    text-align: center;
   }
   
   .foot-logo img {
    max-width: 55px;
   }

.foot-top {
 width: 100%;
 float: left;
}

.foot-area {
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.foot-box {
 width: 100%;
 float: left;
}

.foot-menu-row {
 width: 100%;
 float: left;
 padding-bottom:12px;
 border-bottom: 1px solid rgba(25,25,25,0.2);
}

.foot-menu-row ul {
 margin: 0 auto;
 padding: 0;
 list-style-type: none;
 max-width: 420px;
}

.foot-menu-row ul li {
 width: auto;
 padding-right: 20px;
 float: left;
}

.foot-menu-row ul li a {
 width: 100%;
 float: left;
 text-align: left;
 color: #191919;
 font-size: 14px;
 font-family: 'EFCircularLight', Helvetica, Arial;
}

.foot-txt-row {
 width: 100%;
 float: left;
 padding-bottom: 35px;
 border-bottom: 1px solid rgba(255, 255, 255, 0.5);
 color: #191919;
 font-size: 20px;
 font-family: "EFCircularBold", Georgia, sans-serif;
}

.foot-copyright {
 width: 100%;
 float: left;
 color: #191919;
 font-size: 12px;
 text-align: center;
 padding: 16px 0;
}



@media all and (max-width:767px) {
 .foot-logo {
  padding: 48px 0 0;
 }
 .foot-top{
     padding-top: 20px;
 }
 .foot-box {
  padding: 0 0;
 }
 .foot-menu-row {
     
  padding-bottom: 4px;
 }
 .foot-menu-row ul{
     width: 100%;
     float: left;
     margin:0;
     max-width: initial;
 }
 .foot-menu-row ul li {
  width: 100%;
  padding-bottom: 20px;
 }
 .foot-menu-row ul li a {
  font-size: 14px;
  text-align: center;
 }
 .foot-txt-row {
  font-size: 16px;
  padding-bottom: 30px;
 }
 .foot-copyright{
     padding: 16px 0 24px;
 }
}

/* ------------------------------------------------------------ *\
    Accordion 
\* ------------------------------------------------------------ */

.accordion {
 width: 100%;
 float: left;
}

.accordion-item {
 position: relative;
 margin: 2px;
}

.accordion-item::after {
 position: absolute;
 content: " ";
 background: rgba(0, 0, 0, 0.4);
 z-index: 0;
 top: 0;
 left: 0;
 bottom: 0;
 width: 100%;
}

.accomodation-row .accordion-item::after {
 background: #fff;
 z-index: -1;
}

.tab-area .accordion-item::after {
 background: #fff;
 z-index: -1;
}

.accordion .accordion-item .title {
 color: #fff;
 cursor: pointer;
 padding: 18px;
 width: 100%;
 text-align: left;
 border: none;
 outline: none;
 background: transparent;
 position: relative;
 z-index: 1;
 transition: none !important;
}

.tab-area .accordion .accordion-item .title {
 color: #000;
 cursor: pointer;
 width: 100%;
 text-align: left;
 border: none;
 outline: none;
 background: transparent;
 position: relative;
 z-index: 1;
 padding: 30px 0;
 font-size: 14px;
 font-family: "EFCircularMedium", Georgia, sans-serif;
 transition: none !important;
}

.accomodation-row .accordion .accordion-item .title {
 color: #000;
 cursor: pointer;
 width: 100%;
 text-align: left;
 border: none;
 outline: none;
 background: transparent;
 position: relative;
 z-index: 1;
 padding: 30px 0;
 font-size: 18px;
 font-family: "EFCircularMedium", Georgia, sans-serif;
 transition: none !important;
}

.accordion .accordion-item .title::after {
 content: " ";
 float: right;
 margin-left: 5px;
 z-index: 1;
 background: url("images/arrow_down.png") no-repeat;
 width: 20px;
 height: 25px;
 background-size: contain;
 background-position: center;
}

.tab-area .accordion .accordion-item .title::after, .accomodation-row .accordion .accordion-item .title::after {
 content: " ";
 float: right;
 margin-left: 5px;
 z-index: 1;
 background: url("images/arrow_black_down.png") no-repeat;
 width: 10px;
 height: 15px;
 background-size: contain;
 background-position: center;
}

.accordion .accordion-item .panel {
 max-height: 0;
 -webkit-transition: max-height 0.15s ease-out;
 -o-transition: max-height 0.15s ease-out;
 transition: max-height 0.15s ease-out;
 overflow: hidden;
}

.accordion .accordion-item.active .title {
 font-size: 0;
}

.tab-area .accordion .accordion-item.active .title {
 font-size: 14px;
}

.accomodation-row .accordion .accordion-item.active .title {
 font-size: 20px;
}

.accordion .accordion-item.active .title:after {
 background: url("images/arrow_up.png") no-repeat;
 width: 20px;
 height: 25px;
 background-size: contain;
 background-position: center;
}

.tab-area .accordion .accordion-item.active .title:after, .accomodation-row .accordion .accordion-item.active .title:after {
 background: url("images/arrow_black_up.png") no-repeat;
 width: 10px;
 height: 15px;
 background-size: contain;
 background-position: center;
}

.accordion .accordion-item.active .panel {
 max-height: 600px;
 -webkit-transition: max-height 0.25s ease-in;
 -o-transition: max-height 0.25s ease-in;
 transition: max-height 0.25s ease-in;
}

.desAcc .accordion .accordion-item.active .panel {
    max-height: 600px;
    -webkit-transition: max-height 0.25s ease-in;
    -o-transition: max-height 0.25s ease-in;
    transition: max-height 0.25s ease-in;
    min-height: 300px;
}

.mob-show .accordion-item:nth-child(1) {
 background: url("images/zurich.jpg");
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.mob-show .accordion-item:nth-child(2) {
 background: url("images/boston.jpg");
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.mob-show .accordion-item:nth-child(3) {
 background: url("images/london.jpg");
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.mob-show .accordion-item:nth-child(4) {
 background: url("images/dubai.jpg");
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.desAcc .accordion-item:nth-child(1) {
    background: url("images/desNY.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }
   
   .desAcc .accordion-item:nth-child(2) {
    background: url("images/desTOK.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }
   
   .desAcc .accordion-item:nth-child(3) {
    background: url("images/desLON.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }
   
   .desAcc .accordion-item:nth-child(4) {
    background: url("images/desDUB.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   }

.tab-area .accordion-item, .accomodation-row .accordion-item {
 background: #fff;
 border-bottom: 1px solid #ccc;
}

@media all and (max-width:767px) {
 .tab-area .accordion .accordion-item .title, .accomodation-row .accordion .accordion-item .title {
  padding: 20px 20px 20px 0;
 }
 .accomodation-row .accordion .accordion-item.active .title {
  font-size: 20px;
 }
 .accordion .accordion-item .title{
     font-size: 20px;
 }
}

/* ------------------------------------------------------------ *\
    Mobile burger menu 
\* ------------------------------------------------------------ */

.burger-menu, .burger-menu.open {
 display: inline-block;
 cursor: pointer;
 z-index: 9999;
 padding: 4px 0 0;
 position: relative;
}

.burger-menu .bar1, .bar2, .bar3 {
 width: 25px;
 height: 1px;
 background-color: #fff;
 margin: 6px 0 0;
 transition: 0.4s;
}

.white .burger-menu .bar1,.white .burger-menu .bar2,.white .burger-menu .bar3{
    background-color: #000;
}

.burger-menu.open .bar1 {
 -webkit-transform: rotate(-45deg) translate(-6px, 6px);
 transform: rotate(-45deg) translate(-6px, 6px);
 background-color: #000000;
}


.burger-menu.open .bar2 {
 opacity: 0;
}

.burger-menu.open .bar3 {
 -webkit-transform: rotate(43deg) translate(-3px, -4px);
 transform: rotate(43deg) translate(-3px, -4px);
 background-color: #000000;
}

/* Menu */

.popup-overlay {
 background: #fff !important;
}

.popup-content {
 width: 100% !important;
 float: left !important;
 border: none !important;
 background: #fff !important;
 margin: 0 !important;
 padding: 70px 15px 0 !important;
}

.head-link ul li .menu {
 width: 100%;
 float: left;
 text-align: center;
 padding: 0px;
 position: relative;
}
.monMenuLogo{
    position: absolute;
    left:9px;
    top:-47%;
    max-width: 30%;
    z-index: 2;
    opacity: 1 !important;
}

.head-link ul li .menu ul {
 position: relative;
 top: 0px;
 font-size: 24px;
 padding: 0px;
}

.head-link ul li .menu li {
 width: 100%;
 float: left;
 list-style: outside none none;
 margin: 0px;
 padding: 0;
 cursor: pointer;
}

.head-link ul li .menu li a, .head-link ul li .menu li button {
 width: 100%;
 float: left;
 text-align: left;
 padding: 0 0 15px;
 background: transparent;
}

.menu li:hover {
 color: #ff0000;
}


/* ------------------------------------------------------------ *\
    Thank You Page
\* ------------------------------------------------------------ */

.thank-hero {
    width: 100vw;
    height: 630px;
    float: left;
    background: url("images/ThankStageDesk.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
    margin-bottom: 70px;
   }
   
   .thank-hero::after {
    background: linear-gradient(to bottom, rgba(53, 66, 103, 0), #151824);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
   }
   
   .thank-hero-inner {
       left: 0;
       position: absolute;
       right: 0;
       text-align: left;
       top: 50%;
       z-index: 1;
       -webkit-transform: translateY(-50%);
       transform: translateY(-50%);
   }
   
   .thank-hero-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    line-height: 30px;
   }
   
   .thank-hero-content h1 {
    color: #fff;
    font-size: 50px;
    margin-bottom: 10px;
    text-align: center;
   }
 
   
   .section-thank {
    width: 100%;
    float: left;
    background: #fff;
    padding: 62px 0 0;
   }
   
   .thank-area {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
   }
   
   .thank-box {
    width: 100%;
    float: left;
    min-height: 300px;
   }
   
 
   
   .thank-row {
    width: 50%;
    float: left;
    padding-bottom: 50px;
   }
   
   .thank-row img{
    width:78px;
    height: 78px;
    float: left;
   }
   
   .thank-row p {
    width: 450px;
    float: left;
    text-align: left;
    font-size: 24px;
    color: #000;
    padding-left: 32px;
   }
   
   .thankbg{
       background: #f7f5f0;
   }

   .thankbg h1{
       font-size: 32px;
       padding-bottom: 20px;
   }
   
   
   @media all and (max-width:767px) {
    .thank-hero {
     height: 70vh;
     background: url("images/ThankStageMob.jpg");
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     margin-bottom: 0;
    }
    .thank-hero-content {
     padding: 0 20px;
     line-height: normal;
    }
    .thank-hero-content h1 {
     font-size: 20px;
    }
    
    
    .section-thank {
     padding: 50px 20px 20px;
    }

    .thank-box{
        min-height: initial;
    }
    .thank-row{
        width: 100%;
        padding-bottom: 32px;
    }
    .thank-row h1 {
     font-size: 14px;
    }
    .thank-row img{
        width:56px;
        height: 56px;
        float: left;
       }
    .thank-row p {
        width: 255px;
     font-size: 12px;
     font-family: 'EFCircularLight', Helvetica, Arial;
    }

    .thankbg h1{
        font-size: 20px;
        padding-bottom: 17px;
    }
    
   }

/* ------------------------------------------------------------ *\
    small screens
\* ------------------------------------------------------------ */

@media all and (min-width:768px) and (max-width:1250px) {
 .detail-inner, .agent-hero-content, .destination-hero-content, .pq-hero-content, .acc-hero-content .signin-hero-content, .register-hero-content, .school-hero-content, .material-hero-content, .promos-hero-content, .courses-hero-content, .contactUs-detail-inner {
  max-width: 90%;
 }
 .destination-parent, .pq-parent, .acc-parent, .material-area, .offer-area, .prog-area, .office-area, .accred-area, .foot-area, .scArea, .scGallery-area, .scVideo-area, .accomodation-area, .materials-inner, .materials-area, .faq-area, .courses-area, .promosHead, .promos-area, .update-area {
  max-width: 90%;
 }
 .prog-half img, .acc-half img {
  height: 100%;
 }
}



/* ------------------------------------------------------------ *\
    SA Market
\* ------------------------------------------------------------ */

.mkt-sa p,
.mkt-sa h1,
.mkt-sa h2,
.mkt-sa h3,
.mkt-sa h4,
.mkt-sa h5,
.mkt-sa h6,
.mkt-sa div,
.mkt-sa span{
    text-align: right !important;
}

.mkt-sa .hero-content p{
    float: right;
}

.mkt-sa .offAddBlk{
    padding-right: 40px;
    padding-left: 0;
}

.mkt-sa .flex-center p{
    padding-right: 0;
}

.mkt-sa .hero-btn{
    float: right;
}

.mkt-sa .regBtn{
    float: right;
    text-align: right;
}

.mkt-sa .head-link ul li.langNav .langDrop{
    background: url("images/arrow_down.png") no-repeat 5% 50% ;
    background-size: 11px 7px;
}


.mkt-sa .white .head-link ul li.langNav .langDrop{
   background: url("images/arrow_black_down.png") no-repeat 5% 50% ;
   background-size: 11px 7px;
}

.mkt-sa .register-hero-content p,
.mkt-sa .materials-con p{
    float: right;
}

.mkt-sa .destination-hero-content h1,
.mkt-sa .material-hero-content h1{
    text-align: center !important;
}
.mkt-sa .dropArea, .mkt-sa .btnArea{
float: right;
}

.mkt-sa .tab-area .accordion .accordion-item .title{
    text-align: right;
}

.mkt-sa .tab-area .accordion .accordion-item .title::after, .accomodation-row .accordion .accordion-item .title::after{
    float: left;
}

.mkt-sa .coursesdropArea, .mkt-sa .coursesbtnArea{
    float: right;
}

.mkt-sa .downBtn,
.mkt-sa .coursesdownBtn{
    float: right;
    margin-right: 20px;
}

.mkt-sa .pq-hero-content h1{
    text-align: center !important;
}
.mkt-sa .highlighList ul li span.tickImg{
    float: right;
    margin-left: 10px;
}
.mkt-sa .highlighList ul li span.listCon{
    float: right;
}

.mkt-sa .head-link ul li .menu li a, .mkt-sa .head-link ul li .menu li button{
    text-align: right;
}

.mkt-sa .sc-btn-area,
.mkt-sa .sc-acc-btn{
    float: right;
}

.mkt-sa .scGallery-box{
    float: right;
}

.mkt-sa .head-logo{
    text-align: left !important;
}

.mkt-sa .foot-logo,
.mkt-sa .foot-copyright{
    text-align: center !important;
}

@media all and (max-width:767px) {
    .mkt-sa .offAddBlk{
        padding-right: 20px;
    }
    .mkt-sa .offer-list ul li:nth-child(2), .mkt-sa .offer-list ul li:nth-child(4), .offer-list ul li:nth-child(6){
        padding-left: 0;
    }
    .mkt-sa .offer-list ul li:nth-child(1), .mkt-sa .offer-list ul li:nth-child(3), .offer-list ul li:nth-child(5){
       padding-right: 20px;
   }

   .mkt-sa .accordion .accordion-item .title{
       text-align: right;
   }

   .mkt-sa .accordion .accordion-item .title::after{
       float: left;
   }

  
}