.wrapper {
 margin: 50px 30px 0;
}

.reserve-online-wrap p {
 font-size: 15px;
}

.fs-dropdown {
 position: relative;
 z-index: 1;
 display: block;
 max-width: 100%;
}

.fs-dropdown, .fs-dropdown *, .fs-dropdown :after, .fs-dropdown:before, .fs-dropdown:after, .fs-dropdown:before {
 box-sizing: border-box;
 -webkit-transition: none;
 transition: none;
 -webkit-user-select: none !important;
 -moz-user-select: none !important;
 -ms-user-select: none !important;
 user-select: none !important;
}

.fs-dropdown-element {
 width: 100%;
 height: 100%;
 position: absolute;
 left: 0;
 z-index: -1;
 opacity: 0;
 outline: 0;
 -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
 -webkit-tap-highlight-color: transparent;
}

.fs-dropdown-selected {
 border: none;
 padding: 0;
 width: 100%;
 position: relative;
 z-index: 2;
 background: #fff;
 border: 1px solid;
 color: #000;
 cursor: pointer;
 display: block;
 overflow: hidden;
 font-family: 'EFCircularLight', Helvetica, Arial;
 text-overflow: clip;
}

.fs-dropdown-options {
 width: 100%;
 max-height: 550px;
 position: absolute;
 top: 100%;
 left: 0;
 z-index: 5;
 background: #fff;
 border: 1px solid;
 display: none;
 overflow: auto;
 overflow-x: hidden;
}

.fs-dropdown-options.fs-scrollbar {
 position: absolute;
}

.fs-dropdown-group {
 display: block;
 font-size: 75%;
}

.fs-dropdown-item {
 border: none;
 padding: 0;
 width: 100%;
 background: #fff;
 border-radius: 0;
 color: #000;
 cursor: pointer;
 display: block;
 overflow: hidden;
 text-decoration: none;
 text-overflow: ellipsis;
 font-family: 'EFCircularLight', Helvetica, Arial;
}

.fs-dropdown-item_placeholder {
 display: none;
}

.fs-dropdown-item_selected {
 background: #ddd;
}

.fs-dropdown-item_disabled {
 cursor: default;
 opacity: 0.5;
}

.fs-dropdown-open {
 z-index: 3;
}

.fs-dropdown-open .fs-dropdown-options {
 display: block;
}

.fs-dropdown-open .fs-dropdown-selected {
 z-index: 6;
}

.fs-dropdown-cover.fs-dropdown-open .fs-dropdown-selected {
 z-index: 4;
}

.fs-dropdown-cover .fs-dropdown-options {
 top: 0;
}

.fs-dropdown-bottom .fs-dropdown-options {
 top: auto;
 bottom: 100%;
}

.fs-dropdown-bottom.fs-dropdown-cover .fs-dropdown-options {
 top: auto;
 bottom: 0;
}

.fs-dropdown-multiple .fs-dropdown-options {
 width: 100%;
 position: static;
 display: block;
}

.fs-dropdown-disabled {
 opacity: 0.5;
}

.fs-dropdown-disabled .fs-dropdown-selected {
 cursor: default;
}

.fs-dropdown-disabled .fs-dropdown-group, .fs-dropdown-disabled .fs-dropdown-item {
 cursor: default;
}

.fs-dropdown-options.fs-scrollbar {
 overflow: hidden;
}

.fs-dropdown-options.fs-scrollbar .fs-scrollbar-bar {
 border: 1px solid;
 border-width: 0 0 0 1px;
}

.fs-dropdown-options.fs-scrollbar .fs-scrollbar-content {
 max-height: 260px;
 height: auto;
}

.fs-dropdown-options.fs-scrollbar.fs-scrollbar-active .fs-scrollbar-content {
 padding: 0;
}

.fs-dropdown-selected {
 border: 1px solid transparent;
 border-radius: 0;
 box-shadow: none;
 color: #000;
 /* font-family: $font_family--primary; */
 font-size: 12px;
 font-weight: 500;
 line-height: 10px;
 text-transform: uppercase;
 padding: 28px 40px 24px 48px;
 white-space: nowrap;
}

.form-dropdown-icon .fs-dropdown-selected {
 /* background: url(../images/drop-down-arrow-black.svg) no-repeat 89% center #fff; */
 background-size: 13px;
}

.fs-dropdown {
 position: relative;
}

.fs-dropdown .fs-dropdown-item:first-child {
 display: none;
}

/*.fs-dropdown:after { display: inline-block; content: ''; width: 6px; height: 6px; border-top: 1px solid #000; border-right: 1px solid #000; border-width: 1px; -webkit-transform: rotate(135deg); -ms-transform: rotate(135deg); transform: rotate(135deg); right: 25px; position: absolute; top: 50%; z-index: 9999; margin-top: -4px; }*/

.fs-dropdown-options {
 border-radius: 0;
 max-height: 450px;
 padding: 10px 0;
 min-width: 254px;
 border-color: #ccc;
}

.fs-dropdown-group {
 color: #262626;
 /* font-family: $font_family--primary; */
 font-size: 16px;
 font-weight: 400;
 line-height: 1;
 text-transform: none;
 margin: 10px 28px 10px 40px;
 border: none;
 position: relative;
 padding: 0;
}

.fs-dropdown-group:before {
 display: inline-block;
 content: '';
 width: 160px;
 height: 1px;
 background: #979797;
 position: absolute;
 bottom: -5px;
 left: 0;
}

.fs-dropdown-item {
 border: none;
 color: #262626;
 /* font-family: $font_family--primary; */
 font-size: 15px;
 font-weight: 300;
 line-height: 18px;
 padding: 8px 28px 6px 44px;
}

.fs-dropdown-item+.fs-dropdown-group {
 margin-top: 30px;
}

.fs-dropdown-item:hover, .fs-dropdown-item.selected {
 background: #000;
 color: #fff;
}

.form-btn {}

.form-btn:hover {
 background: #d60076;
 color: #fff;
}

.form-btn-transparent.btn {
 padding: 17px 54px 14px 54px;
}

.form-controls {
 position: relative;
}

.form-controls-btn {
 display: inline-block;
 border-radius: 50%;
 text-decoration: none;
 width: 22px;
 height: 22px;
 border: 1px solid #979797;
 position: absolute;
 left: calc(100% + 15px);
 top: 50%;
 margin-top: -11px;
 text-align: center;
 line-height: 17px;
}

.form-controls-btn .ico-cross {
 display: none;
}

.form-controls-btn.active {
 background: #000;
}

.form-controls-btn.active .ico-cross {
 display: inline-block;
}

.form-controls-btn.active .ico-info {
 display: none;
}

.fs-dropdown-open .fs-dropdown-selected {
 border-color: transparent;
}

.valid .fs-dropdown-selected:after {
 position: absolute;
 top: 50%;
 bottom: 0;
 right: 17px;
 width: 16px;
 color: #d57600;
 content: '?';
 height: 20px;
 background: #fff;
}

.reserve-online-wrap h1 {
 color: #000;
 margin-top: 0;
 font-size: 26px;
 margin-bottom: 20px;
 letter-spacing: 0;
}

.reserve-online-wrap .shell {
 max-width: 960px;
}

.reserve-online-left {
 width: 560px;
 float: left;
}

.preferences-reserve {
 list-style: none;
}

.preferences-reserve label {
 display: none;
}

.preferences-reserve .selecter.open .selecter-selected, .preferences-reserve .selecter.focus .selecter-selected, .preferences-reserve .selecter-selected {
 padding: 0;
 border: none;
 border-bottom: 1px solid #909090;
 color: #262626;
 font-size: 15px;
 font-weight: 300;
 line-height: 18px;
 margin-bottom: 4px;
 text-transform: none;
}

.preferences-reserve .selecter-selected:after {
 right: 3px;
 width: 12px;
 height: 12px;
 bottom: 12px;
}

.preferences-reserve .selecter-options {
 border-radius: 0;
}

.preferences-reserve .selecter-item {
 padding: 10px 5px;
}

.preferences-reserve li {
 position: relative;
 clear: both;
}

.preferences-reserve li.valid:hover .selected-content a i {
 display: none;
}

.preferences-reserve li.valid:hover .selected-content a i+i {
 display: block;
}

.preferences-reserve li+li {
 margin-top: 25px;
}

.preferences-reserve li.invalid .selecter-selected {}

.preferences-reserve li.invalid .selecter-selected:after {
 border-top-color: #bd0000;
 border-right-color: #bd0000;
}

.preferences-reserve li.valid .selecter-selected {
 color: #18aa00;
 border-color: #18aa00;
}

.preferences-reserve li.valid .selecter-selected:after {
 border-top-color: #18aa00;
 border-right-color: #18aa00;
 height: 7px;
}

.preferences-reserve li.reserve-prefernce-radiobuttons {
 overflow: hidden;
 display: none;
}

.preferences-reserve li.reserve-prefernce-radiobuttons .form-radiogroup-year {
 margin-bottom: 0;
}

.preferences-reserve li.reserve-prefernce-radiobuttons.disabled::after {
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 content: '';
}

.preferences-reserve li.reserve-prefernce-radiobuttons .form-radiogroup-year .form-radiosingle {
 float: left;
 width: auto;
}

.preferences-reserve li.reserve-prefernce-radiobuttons .form-radiogroup-year input {
 position: absolute;
 top: 0;
 left: 0;
 opacity: 0;
 display: none;
}

.preferences-reserve li.reserve-prefernce-radiobuttons .form-radiogroup-year input+label {
 position: relative;
 display: inline-block;
 font-weight: 300;
 line-height: 1;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 padding: 10px 20px;
 border: 1px solid #ccc;
 border-radius: 5px;
 margin-right: 10px;
}

.preferences-reserve li.reserve-prefernce-radiobuttons .form-radiogroup-year input:checked+label {
 background: #000;
 color: #fff;
}

.preferences-reserve li.reserve-prefernce-radiobuttons .form-radiogroup-year input+label:hover {
 background: #000;
 color: #fff;
}

.preferences-reserve .checkbox {
 padding-top: 8px;
 margin-top: -8px;
}

.preferences-reserve .checkbox label {
 display: inline-block;
}

.reserve-online-right-top .form-progress-text {
 float: right;
 text-align: right;
 text-align: end;
}

.reserve-online-right-top .form-progress-text span {
 display: none;
}

.reserve-online-right-top .form-progress-text span.active {
 display: inline;
}

.reserve-online-content {
 margin-bottom: 40px;
}

.reserve-online-content.reserve-online-personalise {
 margin-bottom: 15px;
}

.reserve-online-content h2 {
 clear: both;
 border-bottom: 1px solid #ccc;
 padding-bottom: 10px;
 margin-bottom: 25px;
 letter-spacing: 0;
 font-weight: 700;
 margin-top: 0;
}

.reserve-online-promo {
 text-align: center;
 padding: 30px 50px;
 border: 1px solid #ccc;
 margin-bottom: 40px;
}

.reserve-online-promo h3 {
 font-size: 21px;
}

.reserve-online-content ul h2 {
 padding: 10px 0;
 margin: 20px 0 20px 10px;
 width: 100%;
 float: left;
}

.reserve-online-left .preferences-reserve {
 width: 320px;
 margin: 0;
}

.reserve-online-left .form-loader {
 position: absolute;
 right: 100%;
 top: 0;
 bottom: 0;
 width: 19px;
 height: 25px;
 margin: auto 5px;
 text-align: center;
 -webkit-animation: spin 2s infinite linear;
 animation: spin 2s infinite linear;
 display: none;
}

.reserve-online-left .loading .form-loader {
 display: block;
}

.reserve-online-left .preferences-reserve>li.disabled:after {
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: -50px;
 content: '';
}

.reserve-online-left .preferences-reserve>li.disabled .form-controls-btn, .reserve-online-left .preferences-reserve>li.disabled .styled-select {
 opacity: 0.5;
}

.reserve-online-left .preferences-reserve>li .form-controls-btn-reserve.disabled {
 opacity: 0.5;
 cursor: pointer;
}

.reserve-online-left .error-text-message {
 display: none;
 color: red;
 font-size: 15px;
}

.reserve-online-left .selected-content {
 position: static;
}

.reserve-online-left .valid .selected-content {
 display: block;
}

.reserve-online-left .selected-content h6 {
 margin: 0 0 3px;
 font-size: 15px;
 line-height: 1.2;
 color: #000;
 font-weight: 300;
}

.reserve-online-left .selected-content p {
 overflow: hidden;
 margin: 0;
 font-size: 13px;
}

.reserve-online-left .selected-content a {
 position: absolute;
 right: 0;
 z-index: 2;
}

.reserve-online-left .selected-content a i {
 position: absolute;
 right: 11px;
 top: 14px;
 display: none;
}

.reserve-online-left .valid .selected-content a i {
 display: block;
}

.reserve-online-left .valid .selected-content a i+i {
 display: none;
}

.reserve-online-left .valid .selected-content a:hover i {
 display: none;
}

.reserve-online-left .valid .selected-content a:hover i+i {
 display: block;
}

.reserve-online-left .fs-dropdown-selected {
 padding: 0 34px 0 14px;
 color: #262626;
 font-size: 15px;
 font-weight: 300;
 line-height: 39px;
 text-transform: none;
 border: 1px solid #ccc;
 border-radius: 4px;
 text-align: left;
}

.reserve-online-left .fs-dropdown-options {
 width: 100%;
 min-width: 0;
 border-color: #ccc;
 margin-top: -1px;
}

.reserve-online-left .fs-dropdown:after {
 right: 0;
 margin-top: -10px;
}

.reserve-online-left .fs-dropdown-item {
 padding: 7px 12px 7px 20px;
 text-align: left;
}

.reserve-online-left .fs-dropdown-open .fs-dropdown-selected {
 border-radius: 4px 4px 0 0;
}

.reserve-online-left .fs-dropdown-selected:after {
 content: '';
 position: absolute;
 top: 50%;
 right: 14px;
 z-index: 9;
 display: inline-block;
 width: 10px;
 height: 10px;
 border-top: 1px solid #000;
 border-right: 1px solid #000;
 border-width: 1px;
 margin-top: -7px;
 transform: rotate(135deg);
}

.reserve-online-left .fs-dropdown-open .fs-dropdown-selected:after {
 margin-top: 0;
 transform: rotate(-45deg);
}

.reserve-online-left .valid .fs-dropdown-selected:after {
 display: none;
}

.booking-summary-wrap .course-summary h3, .booking-summary-wrap .accomodation-summary, .booking-summary-wrap .insurance-summary, .booking-summary-wrap .extra-summary, .booking-summary-wrap .totalprice-summary.desktop-only, .booking-summary-wrap .residence-disclaimer {
 display: none;
}

.reserve-online-wrap .form-controls-btn-reserve {
 top: 0;
 left: 100%;
 z-index: 2;
 text-align: center;
 text-decoration: none;
 position: absolute;
 display: inline-block;
 height: 41px;
 width: 49px;
 border: 1px solid #ccc;
 border-radius: 4px;
 margin: 0 0 0 -1px;
 line-height: 39px;
}

.reserve-online-wrap .form-controls-btn-reserve i+i {
 display: none;
}

.reserve-online-wrap .form-controls-btn.active i+i {
 display: inline-block;
}

.reserve-online-wrap .ParentFirstName {
 clear: both;
}

.reserve-online-wrap .form-content {
 display: none;
 width: 300px;
 background: #fff;
 position: absolute;
 left: 120%;
 border: 1px solid #ccc;
 padding: 20px;
 top: 50%;
 -webkit-transform: translateY(-50%);
 transform: translateY(-50%);
 z-index: 5;
}

.reserve-online-wrap .form-content h4 {
 font-size: 15px;
}

.reserve-online-wrap .reserve-online-thankyou-right .form-content {
 left: 0;
 top: 50px;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}

.reserve-online-wrap .reserve-online-accomdation {
 display: none;
}

.reserve-online-accomdation h4 {
 font-size: 15px;
 margin-bottom: 15px;
 font-weight: 700;
 display: none;
}

.reserve-online-wrap .form-radiogroup {
 position: relative;
 margin-bottom: 40px;
 clear: both;
}

.reserve-online-wrap .form-radiogroup .form-radiosingle {
 display: block;
 margin-bottom: 20px;
 line-height: 30px;
 width: 100%;
 float: left;
}

.reserve-online-wrap .form-radiogroup .form-controls-btn-reserve {
 width: 30px;
 height: 30px;
 line-height: 30px;
}

.reserve-online-wrap .form-radiogroup .form-radiosingle>p {
 display: inline-block;
 float: right;
 line-height: 30px;
 text-align: right;
 text-align: end;
 margin-bottom: 0;
 padding-top: 40px;
 font-size: 14px;
}

.form-radiosingle i {
 font-size: 14px;
}

.reserve-online-wrap #reserve-accom-residence .form-radiosingle>p {
 line-height: 17px;
}

.reserve-online-wrap .form-radiogroup .form-radiosingle p span {
 display: block;
}

.reserve-online-wrap .form-radiogroup .form-radiosingle .form-radio-left {
 display: inline-block;
 float: left;
 position: relative;
 margin-top: 15px;
}

.reserve-online-wrap .form-radiogroup input {
 position: absolute;
 top: 0;
 left: 0;
 opacity: 0;
 display: none;
}

.reserve-online-wrap .form-radiogroup input+label {
 position: relative;
 display: inline-block;
 padding-left: 35px;
 padding-right: 35px;
 font-weight: 300;
 line-height: 22px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 font-size: 13px;
}

.reserve-online-wrap .form-radiogroup input+label:before {
 position: absolute;
 border-radius: 50%;
 content: '';
 left: 0;
 top: 0;
 width: 22px;
 height: 22px;
 border: 1px solid #979797;
}

.reserve-online-wrap .form-radiogroup input+label:after {
 position: absolute;
 border-radius: 50%;
 content: '';
 left: 4px;
 top: 4px;
 width: 14px;
 height: 14px;
 background: #000;
 transition: all 0.3s;
 -webkit-transform: scale(0);
 -ms-transform: scale(0);
 transform: scale(0);
}

.reserve-online-wrap .form-radiogroup #paymnet-brand input+label:before {
 top: 4px;
}

.reserve-online-wrap .form-radiogroup #paymnet-brand input+label:after {
 top: 9px;
}

.reserve-online-wrap .form-radiogroup input:checked+label:after {
 -webkit-transform: scale(1);
 -ms-transform: scale(1);
 transform: scale(1);
}

.reserve-online-wrap .reserve-online-insurance {
 display: none;
}

.reserve-online-insurance h2 {
 display: none;
}

.reserve-online-insurance .form-checkgroup {
 position: relative;
 margin-bottom: 25px;
 clear: both;
 width: 100%;
 float: left;
}

.reserve-online-insurance .form-checkgroup .form-checkgroup-left {
 float: left;
 position: relative;
}

.reserve-online-insurance .form-checkgroup>p {
 float: right;
 font-size: 15px;
 width: 100%;
 text-align: right;
}

.reserve-online-wrap .form-help-wrap {
 display: inline-block;
 position: relative;
}

.reserve-online-insurance .form-checkgroup input {
 position: absolute;
 left: 0;
 right: 0;
 opacity: 0;
 display: none;
}

.reserve-online-insurance .form-checkgroup label {
 position: relative;
 display: inline-block;
 padding-left: 36px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 line-height: 25px;
 margin-right: 10px;
 font-family: "EFCircularBold", Georgia, sans-serif;
 font-size: 15px;
}

.reserve-online-insurance .form-checkgroup label[data-compulsory='true'] {
 opacity: 0.8;
}

.reserve-online-insurance .form-checkgroup label:after {
 position: absolute;
 top: 0;
 left: 0;
 width: 25px;
 height: 25px;
 border: 1px solid #ccc;
 background: transparent;
 background-position: center center;
 content: '';
 transition: all 0.3s;
}

.reserve-online-insurance .form-checkgroup input:checked+label:after {
 border-color: #ccc;
 background-size: 11px 7px;
 background: url("images/pq_img/check.jpg") center center no-repeat #fff;
}

.reserve-online-insurance .form-checkgroup .form-controls-btn-reserve {
 width: 30px;
 height: 30px;
 line-height: 29px;
}

#reserve-online-extradata {
 display: none;
}

.reserve-online-insurance .mandatory-text {
 display: none;
 font-family: "EFCircularBold", Georgia, sans-serif;
 font-size: 15px;
}

.reserve-online-thankyou-right-popup {
 display: none;
}

.reserve-online-right {
 width: 300px;
 float: right;
 position: relative;
}

.booking-summary-wrap {
 width: 100%;
 padding: 30px 0;
 clear: both;
 background: #f7f5f0;
 border: 1px solid #ccc;
 z-index: 3;
}

/*.booking-summary-wrap.fixed{position:fixed;width:300px; top:100px;}*/

.booking-summary-wrap .booking-summary-container {
 overflow: auto;
 padding: 0 20px;
 height: 85%;
}

/*.booking-summary-wrap.fixed-bottom{position:fixed;width:300px;}*/

.booking-summary-wrap h2 {
 letter-spacing: 0px;
 margin-bottom: 35px;
 padding: 0 20px;
}

.booking-summary-wrap .booking-summary h3 {
 font-size: 15px;
 border-bottom: 1px solid #ccc;
 margin-bottom: 10px;
 letter-spacing: 0;
 font-weight: 700;
 text-transform: uppercase;
}

.booking-summary-wrap .booking-summary p {
 margin-bottom: 5px;
 display: table;
 width: 100%;
 font-size: 15px;
}

/* Book now sticky */

.booking-summary-wrap.fixed {
 position: fixed;
 max-width: 300px;
 top: 10px;
}

.booking-summary-wrap.bottom {
 position: absolute;
 max-width: 300px;
 bottom: 0;
}

.booking-summary-wrap .booking-summary p span {
 text-align: left;
 text-align: start;
 max-width: 160px;
 display: table-cell;
 font-size: 15px;
}

.booking-summary-wrap .booking-summary p span+span {
 text-align: right;
 text-align: end;
 min-width: 70px;
}

.booking-summary-wrap .booking-summary h3 span {
 float: right;
 font-family: "EFCircularBold", Georgia, sans-serif;
}

.booking-summary-wrap .booking-summary .course-summary-price {
 text-align: right;
 text-align: end;
 padding: 10px 0 20px;
 font-size: 15px;
}

.booking-summary-wrap .booking-summary.accomodation-summary .course-summary-price {
 border-top: 0;
 padding-top: 0;
}

.booking-summary-wrap .booking-summary #extra-price-text p {
 display: none;
}

.booking-summary-wrap .booking-summary #course-detail-summary {
 border-bottom: 1px solid #ccc;
}

.booking-summary-wrap .booking-summary #insurance-price-summary {
 display: none;
}

.booking-summary-wrap .booking-summary #extra-price-summary {
 display: none;
}

.booking-summary-wrap .booking-summary #extra-price-summary {
 display: none;
}

/*** custom css for book now form content p trag ****/

.form-content-mobile p {
 width: 100%;
 float: left;
 font-size: 15px;
}

.reservePad {
 padding-bottom: 10px;
}

/* ------------------------------------------------------------ *\
    Mobile
\* ------------------------------------------------------------ */

@media all and (max-width: 767px) {
 .wrapper {
  margin: 0;
 }
 .reserve-online-wrap {
  width: 100%;
  padding: 0 0;
 }
 .reserve-online-wrap .shell {
  width: 100%;
  float: left;
  max-width: 100%;
 }
 .reserve-online-left, .reserve-online-right {
  width: 100%;
  float: left;
 }
 .reserve-online-left .preferences-reserve {
  width: 100%;
 }
 .reserve-online-content h2 {
  font-size: 18px;
 }
 .reserve-online-wrap p {
  font-family: 'EFCircularLight', Helvetica, Arial;
 }
 .reserve-online-wrap #reserve-accom-residence .form-radiosingle>p {
  padding-top: 0;
 }
}